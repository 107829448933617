import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {PhoneTcTokensQuery} from "../Model/PhoneTcTokensQuery";
import {UnifiedTcToken} from "../Model/UnifiedTcToken";
import {PhoneApi} from "../Api/PhoneApi";
import {apiProvider} from "./apiProvider";

export interface PhoneTcTokensState {
    query: PhoneTcTokensQuery | null,
    tcTokens: UnifiedTcToken[] | null
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
    selected: UnifiedTcToken | null
}
export const initialState: PhoneTcTokensState = {
    query: null,
    tcTokens: null,
    loadMessage: null,
    status: 'idle',
    selected: null
}

export const loadPhoneTcTokensAsync = createAsyncThunk(
    'phoneTcTokens/loadPhoneTcTokensAsync',
    apiProvider(PhoneApi, (request: PhoneTcTokensQuery, api: PhoneApi) => {
        return api.queryPhoneTcTokens(request)
    })
)

export const phoneTcTokensSlice = createSlice({
    name: 'phoneTcTokens',
    initialState,
    reducers: {
        selectTcToken: (state, action: PayloadAction<UnifiedTcToken | null>) => {
          state.selected = action.payload
        },
        changePhoneTcTokensQuery: (state, action: PayloadAction<PhoneTcTokensQuery | null>) => {
            state.query = action.payload
            state.tcTokens = null
            state.selected = null
        },
        refreshPhoneTcTokens: (state) => {
            if (state.query) {
                state.query = {...state.query}
                state.tcTokens = null
                state.selected = null
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadPhoneTcTokensAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadPhoneTcTokensAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.loadMessage = null
            state.tcTokens = action.payload
        }).addCase(loadPhoneTcTokensAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.tcTokens = null
            state.loadMessage = action.error.message ?? 'Error occurred loading tc tokens'
        })
    }
})

export default phoneTcTokensSlice.reducer;
export const { changePhoneTcTokensQuery, refreshPhoneTcTokens, selectTcToken } = phoneTcTokensSlice.actions;
export const selectPhoneTcTokensState = (state: RootState) => state.phoneTcTokens;
