import {UserAuthorization} from "../Schema/models/UserAuthorization";
import {requireValue} from "../Utils";

export type AuthorizedUser = UserAuthorization;

export function newAuthorizedUser(user: UserAuthorization): AuthorizedUser {
    return {
        expires_in: requireValue(user.expires_in),
        token: requireValue(user.token),
        name: requireValue(user.name),
        scopes: requireValue(user.scopes)
    }
}

export function haveAccess(user: UserAuthorization, scope: string): boolean {
    return user.scopes.includes(scope);
}

export function canRequestAccess(user: UserAuthorization, scope: string): boolean {
    return false;
}