import {Api} from "./Api";
import {UserLoginRequest} from "../Schema/models/UserLoginRequest";
import {UserLoginResponse} from "../Schema/models/UserLoginResponse";
import {UserRefreshRequest} from "../Schema/models/UserRefreshRequest";
import {UserRefreshResponse} from "../Schema/models/UserRefreshResponse";

export class AuthApi extends Api {
    async userLogin(request: UserLoginRequest): Promise<UserLoginResponse> {
        return this.newCall<UserLoginResponse>('manage/user/login')
            .withoutAuthorization()
            .withMethod('POST')
            .withBody(request)
            .fetchJson()
    }

    async userRefresh(request: UserRefreshRequest): Promise<UserRefreshResponse> {
        return this.newCall<UserRefreshResponse>('manage/user/refresh')
            .withMethod('POST')
            .withBody(request)
            .fetchJson()
    }
}