import {PropsWithChildren} from "react";
import {Card, Spinner} from "react-bootstrap";
import {ArrowClockwise, ChevronDown, XLg} from "react-bootstrap-icons";

import styles from './ActionCardTitle.module.css'

export enum ActionCardCloseType {
    CLOSE = 'close',
    COLLAPSE = 'collapse'
}

export interface ActionCardTitleProps extends PropsWithChildren {
    className?: string

    closeType?: ActionCardCloseType
    loading?: boolean

    canClose?: boolean
    canRefresh?: boolean
    onClose?: () => void
    onRefresh?: () => void
}

const ActionCardTitleClose = (props: { closeType: ActionCardCloseType, onClose: () => void }) => {
    return (
        <span onClick={props.onClose} className={styles.action}>
            {props.closeType === ActionCardCloseType.CLOSE ? <XLg /> : <ChevronDown />}
        </span>
    )
}

export const ActionCardTitle = (props: ActionCardTitleProps) => {
    return (
        <Card.Title className={props.className}>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <span>{props.children}</span>
                    {props.loading === true ? <Spinner size='sm' /> : null}
                </div>
                <div className={styles.actions}>
                    {props.onRefresh !== undefined && (props.canRefresh === undefined || props.canRefresh) ?
                        <span onClick={props.onRefresh} className={styles.action}><ArrowClockwise size='1.2em' /></span>
                    : null}
                    {props.onClose !== undefined && (props.canClose === undefined || props.canClose) ?
                       <ActionCardTitleClose onClose={props.onClose} closeType={props.closeType ?? ActionCardCloseType.CLOSE} />
                    : null}
                </div>
            </div>
        </Card.Title>
    )
}