import {UnifiedUser, unifiedUserKey} from "../../Core/Model/UnifiedUser";
import {Card, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import * as React from "react";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../Core/Store/hooks";
import {selectModelsState} from "../../Core/Store/modelsSlice";
import styles from '../UserDetails/UnifiedUserDetailsView.module.css'
import {
    changeUserSubscriptionQuery,
    loadSubscriptionsAsync,
    selectSubscription,
    selectUserSubscriptionsState
} from "../../Core/Store/userSubscriptionsSlice";
import {
    UnifiedSubscription,
    unifiedSubscriptionActive,
    unifiedSubscriptionKey
} from "../../Core/Model/UnifiedSubscription";
import {UnifiedSubscriptionRowView} from "../SubscriptionRow/UnifiedSubscriptionRowView";
import {
    changeUserContractQuery,
    loadContractsAsync,
    selectContract,
    selectUserContractsState
} from "../../Core/Store/userContractsSlice";
import {UnifiedContract, unifiedContractKey} from "../../Core/Model/UnifiedContract";
import {UnifiedContractRowView} from "../ContractRow/UnifiedContractRowView";
import {Properties, PropertiesTable} from "../PropertyTable";
import {
    changeWebSessionQuery,
    loadWebSessionsAsync,
    selectUserWebSessionsState,
    selectWebSession
} from "../../Core/Store/userWebSessionsSlice";
import {UnifiedWebSession, unifiedWebSessionKey} from "../../Core/Model/UnifiedWebSession";
import {UnifiedWebSessionRowView} from "../WebSessionRow/UnifiedWebSessionRowView";
import {ErrorView} from "../ErrorView";
import {useRequest} from "../../Core/useRequest";
import {ActionCardCloseType, ActionCardTitle} from "../ActionCardTitle/ActionCardTitle";
import {selectAuthState} from "../../Core/Store/authSlice";
import {haveAccess} from "../../Core/Model/AuthorizedUser";
import {setDuplicateToDelete} from "../../Core/Store/duplicateDeleteSlice";

export const getUnifiedUserProperties = (unifiedUser: UnifiedUser): Properties => {
    switch (unifiedUser.type) {
        case "AppUserDto": {
            const user = unifiedUser.user
            return [
                {key: "ID", value: user.id},
                {key: "Token", value: user.auth_token},
                {key: "Register Date", value: user.reg_time.toString()},
                {key: "Application", value: user.app_name},
                {key: "Application Version", value: user.app_version},
                {key: "GCM Token", value: user.gcm_token?.slice(0, 10)}
            ]
        }
        case "WaUserDto": {
            const user = unifiedUser.user
            return [
                {key: "ID", value: user.user_id},
                {key: "Token", value: user.login_token},
                {key: "Status", value: user.user_status.user_status_name},
                {key: "Stripe Customer", value: user.stripe_customer_id, hideOnUnset: true, action: { element: () => <a rel='noreferrer noopener' target='_blank' href={`https://dashboard.stripe.com/customers/${user.stripe_customer_id}`} className='link-success pointer'>Open in dashboard</a> }}
            ]
        }
    }
}

const UnifiedUserSubscriptions = ({ unifiedUser }: { unifiedUser: UnifiedUser }) => {
    const [isCollapsed, setCollapsed] = useState(true)
    const dispatch = useAppDispatch()
    const userSubscriptionsState = useAppSelector(selectUserSubscriptionsState)
    const authState = useAppSelector(selectAuthState)
    useEffect(() => {
        if (userSubscriptionsState.query?.user_id !== unifiedUserKey(unifiedUser)) {
            console.log("changeQuery to ", unifiedUserKey(unifiedUser))
            dispatch(changeUserSubscriptionQuery({ user_id: unifiedUserKey(unifiedUser), model: unifiedUser.model }))
        }
    }, [unifiedUser])
    useRequest(() => {
        if (userSubscriptionsState.status === 'refresh_required' || (userSubscriptionsState.status === 'idle' && userSubscriptionsState.subscriptions === null)) {
            if (userSubscriptionsState.query !== null) {
                dispatch(loadSubscriptionsAsync(userSubscriptionsState.query))
            }
        }
    }, userSubscriptionsState.status, [userSubscriptionsState.query])
    const actualSubscriptions = userSubscriptionsState.query?.user_id === unifiedUserKey(unifiedUser) ?
        userSubscriptionsState.subscriptions : []
    const onSubscriptionSelect = (eventKey: string | null) => {
        if (eventKey !== null) {
            const subscription = actualSubscriptions?.find(e => unifiedSubscriptionKey(e).toString() === eventKey)
            if (subscription !== undefined) {
                dispatch(selectSubscription(subscription))
            }
        }
    }
    const canDelete = authState.user !== null && haveAccess(authState.user, 'delete')
    const onSubscriptionDelete = (subscription: UnifiedSubscription) => {
        if (!canDelete) {
            return
        }
        if (subscription.subscription.duplicate === undefined) {
            return
        }
        dispatch(setDuplicateToDelete({
            info: { name: 'Subscription', duplicate: subscription.subscription.duplicate },
            model: subscription.model,
            id: unifiedSubscriptionKey(subscription)
        }))
    }
    return (
        <Card>
            <Card.Header>
                <ActionCardTitle
                    className={styles.alertTitle}
                    loading={userSubscriptionsState.status === 'loading'}
                    closeType={ActionCardCloseType.COLLAPSE}
                    onClose={() => setCollapsed(!isCollapsed)}
                    canClose={actualSubscriptions !== null && actualSubscriptions.length > 0}
                >
                    Subscriptions
                </ActionCardTitle>
            </Card.Header>
            <ErrorView {...userSubscriptionsState} />
            {!isCollapsed && <ListGroup activeKey={userSubscriptionsState.selected ? unifiedSubscriptionKey(userSubscriptionsState.selected) : null} onSelect={onSubscriptionSelect}>
                {actualSubscriptions?.map(
                    (unifiedSubscription: UnifiedSubscription) => {
                        const key = unifiedSubscriptionKey(unifiedSubscription)
                        const highlighted = userSubscriptionsState.selected ? userSubscriptionsState.selected.subscription?.duplicate?.original_id === key : false
                        return (
                            <ListGroupItem style={{ borderRadius: 0, background: highlighted ? 'rgba(255, 215, 0, 0.5)' : '' }} action key={key} eventKey={key}>
                                <UnifiedSubscriptionRowView onDelete={onSubscriptionDelete} canDelete={canDelete} unifiedSubscription={unifiedSubscription} />
                            </ListGroupItem>
                        )
                    }
                )}
            </ListGroup>}
            {actualSubscriptions && <Card.Footer>
                <div>{actualSubscriptions.length} subscription(s)</div>
                <div>{actualSubscriptions.filter(e => unifiedSubscriptionActive(e)).length} active</div>
            </Card.Footer>}
        </Card>
    )
}

const UnifiedUserContracts = ({ unifiedUser }: { unifiedUser: UnifiedUser }) => {
    const [isCollapsed, setCollapsed] = useState(true)
    const dispatch = useAppDispatch()
    const userContractsState = useAppSelector(selectUserContractsState)
    useEffect(() => {
        if (userContractsState.query?.user_id !== unifiedUserKey(unifiedUser)) {
            dispatch(changeUserContractQuery({ user_id: unifiedUserKey(unifiedUser), model: unifiedUser.model }))
        }
    }, [unifiedUser])
    useRequest(() => {
        if (userContractsState.status === 'refresh_required' || (userContractsState.status === 'idle' && userContractsState.contracts === null)) {
            if (userContractsState.query !== null) {
                dispatch(loadContractsAsync(userContractsState.query))
            }
        }
    }, userContractsState.status, [userContractsState.query])
    const actualContracts = userContractsState.query?.user_id === unifiedUserKey(unifiedUser) ?
        userContractsState.contracts : []
    const onContractSelected = (eventKey: string | null) => {
        if (eventKey !== null && actualContracts !== null) {
            const contract = actualContracts.find(e => unifiedContractKey(e).toString() === eventKey)
            if (contract !== undefined) {
                dispatch(selectContract(contract))
            }
        }
    }
    return (
        <Card>
            <Card.Header>
                <ActionCardTitle
                    className={styles.alertTitle}
                    loading={userContractsState.status === 'loading'}
                    closeType={ActionCardCloseType.COLLAPSE}
                    canClose={actualContracts !== null && actualContracts.length > 0}
                    onClose={() => setCollapsed(!isCollapsed)}
                >
                    Contracts
                </ActionCardTitle>
            </Card.Header>
            <ErrorView {...userContractsState} />
            {!isCollapsed && <ListGroup activeKey={userContractsState.selected ? unifiedContractKey(userContractsState.selected) : null} onSelect={onContractSelected}>
                {actualContracts && actualContracts.map(
                    (unifiedContract: UnifiedContract) => {
                        const key = unifiedContractKey(unifiedContract)
                        return (
                            <ListGroupItem style={{ borderRadius: 0 }} action key={key} eventKey={key}>
                                <UnifiedContractRowView unifiedContract={unifiedContract} />
                            </ListGroupItem>
                        )
                    }
                )}
            </ListGroup>}
            {actualContracts && <Card.Footer>{actualContracts.length} contract(s)</Card.Footer>}
        </Card>
    )
}

export const UnifiedUserRowDetailsContent = ({ unifiedUser }: { unifiedUser: UnifiedUser }) => {
    switch (unifiedUser.type) {
        case "AppUserDto": {
            return (<div></div>)
        }
        case "WaUserDto": {
            return (<div></div>)
        }
        default: {
            return (
                <div>Unknown user type</div>
            )
        }
    }
}

const UnifiedUserWebSessions = ({ unifiedUser }: { unifiedUser: UnifiedUser }) => {
    const [isCollapsed, setCollapsed] = useState(true)
    const dispatch = useAppDispatch()
    const userWebSessionsState = useAppSelector(selectUserWebSessionsState)
    useEffect(() => {
        if (userWebSessionsState.query?.user_id !== unifiedUserKey(unifiedUser)) {
            dispatch(changeWebSessionQuery({ user_id: unifiedUserKey(unifiedUser), model: unifiedUser.model }))
        }
    }, [unifiedUser])
    useRequest(() => {
        if (userWebSessionsState.status === 'refresh_required' || (userWebSessionsState.status === 'idle' && userWebSessionsState.sessions === null)) {
            if (userWebSessionsState.query !== null) {
                dispatch(loadWebSessionsAsync(userWebSessionsState.query))
            }
        }
    }, userWebSessionsState.status, [userWebSessionsState.query])
    const actualSessions = userWebSessionsState.query?.user_id === unifiedUserKey(unifiedUser) ?
        userWebSessionsState.sessions : []
    const onContractSelected = (eventKey: string | null) => {
        if (eventKey !== null && actualSessions !== null) {
            const session = actualSessions.find(e => unifiedWebSessionKey(e).toString() === eventKey)
            if (session !== undefined) {
                dispatch(selectWebSession(session))
            }
        }
    }
    return (
        <Card>
            <Card.Header>
                <ActionCardTitle
                    className={styles.alertTitle}
                    loading={userWebSessionsState.status === 'loading'}
                    closeType={ActionCardCloseType.COLLAPSE}
                    onClose={() => setCollapsed(!isCollapsed)}
                    canClose={actualSessions !== null && actualSessions.length > 0}
                >
                    QR scan sessions
                </ActionCardTitle>
            </Card.Header>
            <ErrorView {...userWebSessionsState} />
            {!isCollapsed && <ListGroup activeKey={userWebSessionsState.selected ? unifiedWebSessionKey(userWebSessionsState.selected) : null} onSelect={onContractSelected}>
                {actualSessions && actualSessions.map(
                    (unifiedWebSession: UnifiedWebSession) => {
                        const key = unifiedWebSessionKey(unifiedWebSession)
                        return (
                            <ListGroupItem style={{ borderRadius: 0 }} action key={key} eventKey={key}>
                                <UnifiedWebSessionRowView unifiedWebSession={unifiedWebSession} />
                            </ListGroupItem>
                        )
                    }
                )}
            </ListGroup>}
            {actualSessions && <Card.Footer>{actualSessions.length} session(s)</Card.Footer>}
        </Card>
    )
}

export const UnifiedUserDetailsView = ({ unifiedUser }: { unifiedUser: UnifiedUser }) => {
    const models = useAppSelector(selectModelsState)
    return (
        <Row>
            <Col>
                <PropertiesTable properties={getUnifiedUserProperties(unifiedUser)} />
                <div className='mt-2'>
                    <UnifiedUserRowDetailsContent unifiedUser={unifiedUser} />
                </div>
                <div className='mt-2'>
                    <UnifiedUserSubscriptions unifiedUser={unifiedUser} />
                </div>
                <div className='mt-2'>
                    <UnifiedUserContracts unifiedUser={unifiedUser} />
                </div>
                <div className='mt-2'>
                    <UnifiedUserWebSessions unifiedUser={unifiedUser} />
                </div>
            </Col>
        </Row>
    )
}