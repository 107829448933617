import {PhoneInfo} from "../../Core/Model/PhoneInfo";
import * as React from "react";
import styles from './PhoneInfoRow.module.css'
import {Badge} from "react-bootstrap";

export const PhoneInfoRow = ({ phoneInfo }: { phoneInfo: PhoneInfo }) => {
    return (
        <>
            <div className={styles.title}>
                <div className={styles.id}>{phoneInfo.phone}</div>
            </div>
            <div>
                <div>{phoneInfo.subscriptions.length} subscriptions</div>
                <div className={styles.badges}>
                    <Badge pill bg='primary'>{phoneInfo.active_count} active</Badge>
                    <Badge pill bg='dark'>{phoneInfo.business_count} business</Badge>
                    <Badge pill bg='success'>{phoneInfo.tracking_count} tracking</Badge>
                    <Badge pill bg='secondary'>{phoneInfo.users.size} users</Badge>
                </div>
            </div>
        </>
    )
}