import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {DuplicateDeleteDialogProps} from "../../Views/DuplicateDeleteDialog/DuplicateDeleteDialog";
import {apiProvider} from "./apiProvider";
import {DeleteDuplicateApi} from "../Api/DeleteDuplicateApi";
import {DeleteDuplicateRequest} from "../Model/DeleteDuplicateRequest";

interface DuplicateDeleteState {
    current: DuplicateDeleteDialogProps | null
    status: 'loading' | 'idle'
    errorMessage: string | null,
    response: boolean | null
}

const initialState: DuplicateDeleteState = {
    current: null,
    status: 'idle',
    errorMessage: null,
    response: null
}

export const deleteDuplicateAsync = createAsyncThunk(
    'duplicateDelete/deleteDuplicateAsync',
    apiProvider(DeleteDuplicateApi, (request: DeleteDuplicateRequest, api: DeleteDuplicateApi) => {
        return api.deleteDuplicate(request.model, request.duplicateKey)
    })
)

const duplicateDeleteSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setDuplicateToDelete: (state, action: PayloadAction<DuplicateDeleteDialogProps | null>) => {
            state.current = action.payload
            state.status = 'idle'
            state.errorMessage = null;
            state.response = null
        }
    },
    extraReducers: builder => {
        builder.addCase(deleteDuplicateAsync.pending, (state) => {
            state.status = 'loading'
            state.errorMessage = null
            state.response = null
        }).addCase(deleteDuplicateAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.errorMessage = null
            state.response = true
        }).addCase(deleteDuplicateAsync.rejected, (state, action) => {
            state.status = 'idle'
            state.errorMessage = action.error.message ?? "Error occurred while performing duplicate delete"
            state.response = false
        })
    }
})

export default duplicateDeleteSlice.reducer;
export const { setDuplicateToDelete } = duplicateDeleteSlice.actions;
export const selectDuplicateDeleteState = (state: RootState) => state.duplicateDelete;