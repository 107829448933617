import {UnifiedActivity} from "../../Core/Model/UnifiedActivity";

const UnifiedActivityRowContent = ({ unifiedActivity }: { unifiedActivity: UnifiedActivity }) => {
    switch (unifiedActivity.type) {
        case "VisitNoteDto": {
            return (
                <div>
                    <div>{unifiedActivity.activity.add_time.toString()}</div>
                    <div>{unifiedActivity.activity.is_online ? 'Online' : 'Offline'}</div>
                </div>
            )
        }
        case "WaActivityRecordDto": {
            return (
                <div>
                    <div>{unifiedActivity.activity.actual_date.toString()}</div>
                    <div>{unifiedActivity.activity.is_online ? 'Online' : 'Offline'}</div>
                </div>
            )
        }
    }
}

export const UnifiedActivityRowView = ({ unifiedActivity }: { unifiedActivity: UnifiedActivity }) => {
    return (
        <div>
            <UnifiedActivityRowContent unifiedActivity={unifiedActivity} />
        </div>
    )
}