import {createAsyncThunk, createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {UnifiedUser, unifiedUserKey} from "../Model/UnifiedUser";
import {UsersQuery} from "../Model/UsersQuery";
import {UsersApi} from "../Api/UsersApi";
import {apiProvider} from "./apiProvider";
import {shallowEqual} from "react-redux";
import {PhoneSubscriptionQuery} from "../Model/SubscriptionQuery";
import {PhoneApi} from "../Api/PhoneApi";
import {PhoneInfo} from "../Model/PhoneInfo";

export interface PhonesState {
    query: PhoneSubscriptionQuery | null,
    phones: PhoneInfo[] | null
    selected: PhoneInfo | null,
    currentQuery: PhoneSubscriptionQuery | null,
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
    refreshed: PhoneInfo | null
}
export const initialState: PhonesState = {
    query: null,
    phones: null,
    currentQuery: null,
    selected: null,
    loadMessage: null,
    status: 'idle',
    refreshed: null
}

export const loadPhonesAsync = createAsyncThunk(
    'users/loadPhonesAsync',
    apiProvider(PhoneApi, (request: PhoneSubscriptionQuery, api: PhoneApi, state) => {
        return api.queryPhones(request)
    })
)

export const phonesSlice = createSlice({
    name: 'phones',
    initialState,
    reducers: {
        selectPhone: (state, action: PayloadAction<PhoneInfo | null>) => {
            state.selected = action.payload
            state.refreshed = null
        },
        changePhoneQuery: (state, action: PayloadAction<PhoneSubscriptionQuery | null>) => {
            state.query = action.payload
            state.phones = null
            state.refreshed = null
        },
        refreshPhones: (state) => {
            if (state.query !== null) {
                state.query = {...state.query}
                state.phones = null
                state.refreshed = state.selected
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadPhonesAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadPhonesAsync.fulfilled, (state, action) => {
            const users = action.payload
            state.status = 'idle'
            state.loadMessage = null
            state.phones = users
            const s = current(state)
            if (s.refreshed !== null && !shallowEqual(s.currentQuery, s.query)) {
                state.selected = null
            } else if (s.refreshed !== null) {
                state.selected = users.find(u => u.phone === s.refreshed?.phone) ?? null
            }
            state.refreshed = null
            state.currentQuery = state.query
        }).addCase(loadPhonesAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.phones = null
            state.loadMessage = action.error.message ?? 'Error occurred loading users'
            state.selected = null
            state.refreshed = null
        })
    }
})

export default phonesSlice.reducer;
export const { selectPhone, changePhoneQuery, refreshPhones } = phonesSlice.actions;
export const selectPhonesState = (state: RootState) => state.phones;
