import {
    UnifiedSubscription,
    unifiedSubscriptionKey,
    unifiedSubscriptionPhone
} from "../../Core/Model/UnifiedSubscription";
import {Alert, Card, ListGroup, ListGroupItem, Spinner, Table} from "react-bootstrap";
import * as React from "react";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../Core/Store/hooks";
import styles from "../UserDetails/UnifiedUserDetailsView.module.css";
import {ChevronDown, ExclamationTriangle} from "react-bootstrap-icons";
import {
    changeSubscriptionActivityQuery, loadSubscriptionActivityAsync,
    selectSubscriptionActivityState
} from "../../Core/Store/subscriptionActivitySlice";
import {UnifiedActivity, unifiedActivityKey, unifiedActivityStatus} from "../../Core/Model/UnifiedActivity";
import {UnifiedActivityRowView} from "../ActivityRow/UnifiedActivityRowView";
import {
    changePhoneTcTokensQuery,
    loadPhoneTcTokensAsync,
    selectPhoneTcTokensState, selectTcToken
} from "../../Core/Store/phoneTcTokensSlice";
import {UnifiedTcToken, unifiedTcTokenKey} from "../../Core/Model/UnifiedTcToken";
import {UnifiedTcTokenRowView} from "../TcTokenRow/UnifiedTcTokenRowView";
import {Properties, PropertiesTable} from "../PropertyTable";
import {useRequest} from "../../Core/useRequest";
import {ErrorView} from "../ErrorView";
import {selectSubscription} from "../../Core/Store/userSubscriptionsSlice";

export const getUnifiedSubscriptionProperties = (unifiedSubscription: UnifiedSubscription): Properties => {
    switch (unifiedSubscription.type) {
        case "SubscriptionPhoneDto": {
            const subscription = unifiedSubscription.subscription
            return [
                {key: "ID", value: subscription.id},
                {key: "Add Time", value: subscription.add_time.toString(), additional: true},
                {key: "Update Time", value: subscription.last_change_time?.toString() ?? "Never", additional: true},
                {key: "Phone", value: subscription.phone_num.toString()},
                {key: "Name", value: subscription.assigned_name},
                {key: "Status", value: subscription.status.name},
                {key: "Online Visibility", value: subscription.online_visibility.name, additional: true},
                {key: "Notifications", value: subscription.notify ? 'Enabled' : 'Disabled', additional: true},
                {key: "Account Type", value: subscription.is_business ? 'Business' : 'Normal'},
                {key: "TC Token", value: subscription.tc_token_id ?? "Not set"}
            ]
        }
        case "WaUserSubscriptionDto": {
            const subscription = unifiedSubscription.subscription
            return [
                {key: "ID", value: subscription.subscription_id},
                {key: "Type", value: subscription.type.subscription_type_name},
                {key: "Add Time", value: subscription.create_date.toString()},
                {key: "Actual Date", value: subscription.actual_date?.toString() ?? "Never"},
                {key: "Status Update Time", value: subscription.last_status_change_date?.toString() ?? "Never"},
                {key: "Phone", value: subscription.phone_num.toString()},
                {key: "Name", value: subscription.subscription_name},
                {key: "Status", value: subscription.status.subscription_status_name},
                {key: "Online Visibility", value: subscription.online_visibility?.online_visibility_status_name ?? "N/A"},
                {key: "Notifications", value: subscription.online_notify ? 'Enabled' : 'Disabled'},
                {key: "Account Type", value: subscription.is_business ? 'Business' : 'Normal'},
                {key: "TC Token", value: subscription.tc_token_id ?? "Not set"}
            ]
        }
    }
}

const UnifiedSubscriptionDetailsContent = ({ unifiedSubscription }: { unifiedSubscription: UnifiedSubscription }) => {
    switch (unifiedSubscription.type) {
        case "WaUserSubscriptionDto": {
            return (
                <div></div>
            )
        }
        case "SubscriptionPhoneDto": {
            return (
                <div></div>
            )
        }
        default: {
            return (
                <div>Unknown subscription type</div>
            )
        }
    }
}

const UnifiedSubscriptionActivity = ({ unifiedSubscription }: { unifiedSubscription: UnifiedSubscription }) => {
    const [isCollapsed, setCollapsed] = useState(true)
    const dispatch = useAppDispatch()
    const subscriptionActivityState = useAppSelector(selectSubscriptionActivityState)
    useEffect(() => {
        if (subscriptionActivityState.query?.subscription_id !== unifiedSubscriptionKey(unifiedSubscription)) {
            dispatch(changeSubscriptionActivityQuery({ subscription_id: unifiedSubscriptionKey(unifiedSubscription), model: unifiedSubscription.model }))
        }
    }, [unifiedSubscription])
    useRequest(() => {
        if (subscriptionActivityState.status === 'refresh_required' || (subscriptionActivityState.status === 'idle' && subscriptionActivityState.activities === null)) {
            if (subscriptionActivityState.query !== null) {
                dispatch(loadSubscriptionActivityAsync(subscriptionActivityState.query))
            }
        }
    }, subscriptionActivityState.status, [subscriptionActivityState.query])
    const actualActivities = subscriptionActivityState.query?.subscription_id === unifiedSubscriptionKey(unifiedSubscription) ?
        subscriptionActivityState.activities : []
    return (
        <Card>
            <Card.Header>
                <Card.Title className={styles.alertTitle}>
                    <div className={styles.collapseTitle}>
                        <div className={styles.titleWithProgress}>
                            <span>Activity</span>
                            {subscriptionActivityState.status === 'loading' && <span>
                                <Spinner size='sm' />
                            </span>}
                        </div>
                        <ChevronDown onClick={() => setCollapsed(!isCollapsed)} className={styles.collapseKey}/>
                    </div>
                </Card.Title>
            </Card.Header>
            <ErrorView {...subscriptionActivityState} />
            {!isCollapsed && <ListGroup>
                {actualActivities?.map(
                    (unifiedActivity: UnifiedActivity) => {
                        const key = unifiedActivityKey(unifiedActivity)
                        const status = unifiedActivityStatus(unifiedActivity)
                        return (
                            <ListGroupItem variant={status ? 'success' : 'danger'} style={{ borderRadius: 0 }} key={key}>
                                <UnifiedActivityRowView unifiedActivity={unifiedActivity} />
                            </ListGroupItem>
                        )
                    }
                )}
            </ListGroup>}
            {actualActivities && <Card.Footer>{actualActivities.length} activities</Card.Footer>}
        </Card>
    )
}

export const UnifiedSubscriptionTcTokens = ({ unifiedSubscription }: { unifiedSubscription: UnifiedSubscription }) => {
    const [isCollapsed, setCollapsed] = useState(true)
    const dispatch = useAppDispatch()
    const phoneTcTokensState = useAppSelector(selectPhoneTcTokensState)
    useEffect(() => {
        if (phoneTcTokensState.query?.phone !== unifiedSubscriptionPhone(unifiedSubscription)) {
            dispatch(changePhoneTcTokensQuery({ phone: unifiedSubscriptionPhone(unifiedSubscription), model: unifiedSubscription.model }))
        }
    }, [unifiedSubscription])
    useRequest(() => {
        if (phoneTcTokensState.status === 'refresh_required' || (phoneTcTokensState.status === 'idle' && phoneTcTokensState.tcTokens === null)) {
            if (phoneTcTokensState.query !== null) {
                dispatch(loadPhoneTcTokensAsync(phoneTcTokensState.query))
            }
        }
    }, phoneTcTokensState.status, [phoneTcTokensState.query])
    const actualTcTokens = phoneTcTokensState.query?.phone === unifiedSubscriptionPhone(unifiedSubscription) ?
        phoneTcTokensState.tcTokens : []
    const onTcTokenSelect = (eventKey: string | null) => {
        if (eventKey !== null) {
            const tcToken = actualTcTokens?.find(e => unifiedTcTokenKey(e).toString() === eventKey)
            if (tcToken !== undefined) {
                dispatch(selectTcToken(tcToken))
            }
        }
    }
    return (
        <Card>
            <Card.Header>
                <Card.Title className={styles.alertTitle}>
                    <div className={styles.collapseTitle}>
                        <div className={styles.titleWithProgress}>
                            <span>Tc Tokens</span>
                            {phoneTcTokensState.status === 'loading' && <span>
                                <Spinner size='sm' />
                            </span>}
                        </div>
                        <ChevronDown onClick={() => setCollapsed(!isCollapsed)} className={styles.collapseKey}/>
                    </div>
                </Card.Title>
            </Card.Header>
            <ErrorView {...phoneTcTokensState} />
            {!isCollapsed && <ListGroup onSelect={onTcTokenSelect}>
                {actualTcTokens?.map(
                    (unifiedTcToken: UnifiedTcToken) => {
                        const key = unifiedTcTokenKey(unifiedTcToken)
                        const highlighted = unifiedTcToken.original.tc_token_id == phoneTcTokensState.selected?.original.tc_token_id
                        return (
                            <ListGroupItem  style={{ borderRadius: 0, background: highlighted ? 'rgba(255, 215, 0, 0.5)' : '' }}  key={key} action eventKey={key}>
                                <UnifiedTcTokenRowView tokenInUse={unifiedSubscription.subscription.tc_token_id} unifiedTcToken={unifiedTcToken} />
                            </ListGroupItem>
                        )
                    }
                )}
            </ListGroup>}
            {actualTcTokens && <Card.Footer>{actualTcTokens.length} tc tokens</Card.Footer>}
        </Card>
    )
}

export const UnifiedSubscriptionDetailsView = ({ unifiedSubscription }: { unifiedSubscription: UnifiedSubscription }) => {
    return (
        <>
            <PropertiesTable properties={getUnifiedSubscriptionProperties(unifiedSubscription)} />
            <div className='mt-2'>
                <UnifiedSubscriptionDetailsContent unifiedSubscription={unifiedSubscription} />
            </div>
            <div className='mt-2'>
                <UnifiedSubscriptionActivity unifiedSubscription={unifiedSubscription} />
            </div>
            <div className='mt-2'>
                <UnifiedSubscriptionTcTokens unifiedSubscription={unifiedSubscription} />
            </div>
        </>
    )
}