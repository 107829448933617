import {createContext, ReactElement, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {AuthorizedUser} from "../../Core/Model/AuthorizedUser";

export const UserContext = createContext<AuthorizedUser | null>(null)

export const useUser: () => AuthorizedUser = () => {
    const navigate = useNavigate()
    const user = useContext(UserContext)
    if (user === null) {
        navigate('/')
        throw new Error();
    }
    return user;
}

export const useUserOrNull : () => (AuthorizedUser | null) = () => {
    return useContext(UserContext)
}

export const UserProvider = (props: { user: AuthorizedUser, children: ReactElement }) => {
    return (
        <UserContext.Provider value={props.user}>
            {props.children}
        </UserContext.Provider>
    )
}