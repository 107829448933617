import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Form,
    InputGroup,
    ListGroup,
    ListGroupItem,
    Row,
    Spinner
} from "react-bootstrap";

import styles from './UsersDashboard.module.css'
import {loadModelsAsync, modelsValidation, selectModelsState} from "../../../Core/Store/modelsSlice";
import {ModelMetadata} from "../../../Core/Schema/models/ModelMetadata";
import {createRef, FormEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../Core/Store/hooks";
import {
    changeUserQuery,
    loadUsersAsync,
    refreshUser,
    selectUser,
    selectUsersState
} from "../../../Core/Store/usersSlice";
import {UsersQuery, usersQuery} from "../../../Core/Model/UsersQuery";
import {UnifiedUser, unifiedUserKey} from "../../../Core/Model/UnifiedUser";
import {UnifiedUserRowView} from "../../../Views/UserRow/UnifiedUserRowView";
import {UnifiedUserDetailsView} from "../../../Views/UserDetails/UnifiedUserDetailsView";
import {
    refreshSubscriptions,
    selectSubscription,
    selectUserSubscriptionsState
} from "../../../Core/Store/userSubscriptionsSlice";
import {unifiedSubscriptionKey} from "../../../Core/Model/UnifiedSubscription";
import {UnifiedSubscriptionDetailsView} from "../../../Views/SubscriptionDetails/UnifiedSubscriptionDetailsView";
import {refreshUserContracts, selectContract, selectUserContractsState} from "../../../Core/Store/userContractsSlice";
import {unifiedContractKey} from "../../../Core/Model/UnifiedContract";
import {UnifiedContractDetailsView} from "../../../Views/ContractDetails/UnifiedContractDetailsView";
import {useRequest} from "../../../Core/useRequest";
import {ErrorView} from "../../../Views/ErrorView";
import {
    pushQueryHistory,
    QueryHistoryType,
    selectQueryHistoryState
} from "../../../Core/Store/queryHistorySlice";
import {ClockHistory} from "react-bootstrap-icons";
import {
    refreshWebSessions,
    selectUserWebSessionsState,
    selectWebSession
} from "../../../Core/Store/userWebSessionsSlice";
import { unifiedWebSessionKey } from "../../../Core/Model/UnifiedWebSession";
import { WebSessionDetailsView } from "../../../Views/WebSessionDetails/WebSessionDetailsView";
import {ActionCardTitle} from "../../../Views/ActionCardTitle/ActionCardTitle";
import {refreshSubscriptionActivity} from "../../../Core/Store/subscriptionActivitySlice";
import {refreshPhoneTcTokens} from "../../../Core/Store/phoneTcTokensSlice";

const QueryLine = () => {
    const queryRef = createRef<HTMLInputElement>()
    const modelsRef = createRef<HTMLSelectElement>()
    const dispatch = useAppDispatch()
    const modelsState = useAppSelector(selectModelsState)
    const queryHistoryState = useAppSelector(selectQueryHistoryState)
    const queryDisabled = modelsState.status !== 'idle' && modelsState.status !== 'refresh_required'
    useRequest(() => {
        if (modelsState.status === 'refresh_required' || (modelsState.status === 'idle' && modelsState.models === null)) {
            dispatch(loadModelsAsync())
        }
    }, modelsState.status, [])
    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const target = event.target as HTMLFormElement
        const textInput = target.elements.namedItem('query') as HTMLInputElement
        const modelInput = target.elements.namedItem('model') as HTMLInputElement
        const text = textInput.value
        const model = modelInput.value
        try {
            const query = usersQuery(model, text)
            dispatch(pushQueryHistory({ type: QueryHistoryType.USERS, date: new Date().getTime(), model: model, value: text }))
            dispatch(modelsValidation(null))
            dispatch(changeUserQuery(query))
        } catch (e: any) {
            if (e instanceof Error) {
                dispatch(modelsValidation(e.message))
            } else {
                dispatch(modelsValidation('Could not validate query'))
            }
        }
    }
    const onHistorySelect = (eventKey: string | null) => {
        if (eventKey === null || !eventKey.match(/\d+/)) {
            return
        }
        const index = Number(eventKey)
        const entry = index >= 0 && index < queryHistoryState.users.length ? queryHistoryState.users[index] : null
        if (entry !== null) {
            const currentQuery = queryRef.current
            const currentModels = modelsRef.current
            if (currentQuery && currentModels) {
                currentQuery.value = entry.value
                currentModels.selectedIndex = modelsState.models?.findIndex(e => e.name === entry.model) ?? 0
            }
        }
    }
    return (
        <Form onSubmit={onSubmit}>
            <Form.Group as={InputGroup} className={styles.queryLine} hasValidation>
                <Form.Select ref={modelsRef} name='model' isInvalid={modelsState.status !== 'refresh_required' && modelsState.loadMessage !== null} disabled={queryDisabled}>
                    {modelsState.models !== null && modelsState.models.length > 0 ? modelsState.models?.map(
                        (model: ModelMetadata) => {
                            return (
                                <option key={model.name} value={model.name}>
                                    {model.display_name}
                                </option>
                            )
                        }
                    ) : <option disabled>No models</option>}
                </Form.Select>
                <Dropdown onSelect={onHistorySelect}>
                    <Dropdown.Toggle as='div' className='form-control' variant='outline-dark'>
                        <ClockHistory />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {queryHistoryState.users.length === 0 &&
                            <Dropdown.Item disabled>
                                No history yet
                            </Dropdown.Item>}
                        {queryHistoryState.users.map((entry, idx) => {
                            const model = modelsState.models?.find(e => e.name === entry.model)
                            const delta = new Date().getTime() - entry.date
                            const hours = delta / (1000 * 60 * 60)
                            const days = hours / 24
                            return (
                                <Dropdown.Item eventKey={idx} key={entry.date.toString()}>
                                    <div>
                                        <div className={styles.dateLine}>{days > 1 ? `${days.toFixed(0)} days ago` : (hours < 1 ? `Less than hour ago` : `${hours.toFixed(0)} hours ago`)}</div>
                                        {entry.model && <div className={styles.modelLine}>{model?.display_name ?? entry.model}</div>}
                                        <div>{entry.value}</div>
                                    </div>
                                </Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <Form.Control ref={queryRef} name='query' isInvalid={modelsState.validationMessage !== null} disabled={queryDisabled}></Form.Control>
                <Form.Control disabled={queryDisabled} type='submit' value='Query'></Form.Control>
                {modelsState.status !== 'refresh_required' &&
                    <Form.Control.Feedback type='invalid'>{modelsState.loadMessage ?? modelsState.validationMessage}</Form.Control.Feedback>}
            </Form.Group>
        </Form>
    )
}

const QueryView = ({ query }: { query: UsersQuery | null }) => {
    const modelsState = useAppSelector(selectModelsState)
    if (query === null) {
        return (
            <span>Query user by Token or ID</span>
        )
    }
    const model = modelsState.models ? modelsState.models.find(e => e.name === query.model) : undefined;
    return (
        <div>
            {query.token ?? query.user_id} @ {model?.display_name ?? query.model ?? 'Any'}
        </div>
    )
}

const UsersListView = () => {
    const dispatch = useAppDispatch()
    const usersState = useAppSelector(selectUsersState)
    const onUserSelect = (eventKey: string | null) => {
        console.log('onSelect', eventKey)
        if (eventKey === null) {
            return
        }
        const user = usersState.users?.find(e => eventKey === unifiedUserKey(e).toString())
        if (user === undefined) {
            console.log('no user with key', eventKey)
            return
        }
        dispatch(selectUser(user))
    }
    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <Card.Title className={styles.usersTitle}>{/* TODO: Migrate to ActionCardTitle */}
                            <div className={styles.titleWithProgress}>
                                <span>Users</span>
                                {usersState.status === 'loading' && <span>
                                    <Spinner size='sm' />
                                </span>}
                            </div>
                        </Card.Title>
                        <Card.Subtitle>
                            <QueryView query={usersState.query} />
                        </Card.Subtitle>
                    </Card.Header>
                    <ErrorView {...usersState} />
                    <ListGroup activeKey={usersState.selected ? unifiedUserKey(usersState.selected) : null} onSelect={onUserSelect}>
                        {usersState.users?.map(
                            (unifiedUser: UnifiedUser) => {
                                const key = unifiedUserKey(unifiedUser)
                                return (
                                    <ListGroupItem style={{ borderRadius: 0 }} action key={key} eventKey={key}>
                                        <UnifiedUserRowView unifiedUser={unifiedUser} />
                                    </ListGroupItem>
                                )
                            }
                        )}
                    </ListGroup>
                    {usersState.users && <Card.Footer>
                        <div>{usersState.users.length ?? 0} user(s)</div>
                    </Card.Footer>}
                </Card>
            </Col>
        </Row>
    )
}

const SelectedUserView = () => {
    const dispatch = useAppDispatch()
    const usersState = useAppSelector(selectUsersState)
    const onClose = () => {
        dispatch(selectUser(null))
        dispatch(selectContract(null))
        dispatch(selectWebSession(null))
    }
    const canClose = usersState.selected !== null
    const onRefresh = () => {
        dispatch(refreshUser())
        dispatch(refreshSubscriptions())
        dispatch(refreshUserContracts())
        dispatch(refreshWebSessions())
    }
    const canRefresh = usersState.selected !== null && usersState.status === 'idle'
    return (
        <Card>
            <Card.Header>
                <ActionCardTitle onClose={onClose} canClose={canClose} onRefresh={onRefresh} canRefresh={canRefresh}>
                    {usersState.selected ? <>User {unifiedUserKey(usersState.selected)}</> : <>User details</>}
                </ActionCardTitle>
                <Card.Subtitle>
                    {usersState.selected ? null : <>Select user to see details</>}
                </Card.Subtitle>
            </Card.Header>
            {usersState.selected ? <Card.Body>
                <UnifiedUserDetailsView unifiedUser={usersState.selected} />
            </Card.Body> : null}
        </Card>
    )
}

const SelectedSubscriptionView = () => {
    const usersState = useAppSelector(selectUsersState)
    const dispatch = useAppDispatch()
    const userSubscriptionState = useAppSelector(selectUserSubscriptionsState)
    const onClose = () => dispatch(selectSubscription(null))
    const canClose = userSubscriptionState.selected !== null
    const onRefresh = () => {
        dispatch(refreshSubscriptions())
        dispatch(refreshSubscriptionActivity())
        dispatch(refreshPhoneTcTokens())
    }
    const canRefresh = userSubscriptionState.selected !== null && userSubscriptionState.status === 'idle'
    if (userSubscriptionState.selected === null) {
        return null;
    }
    return (
        <Row className='mb-2'>
            <Col>
                <Card>
                    <Card.Header>
                        <ActionCardTitle onClose={onClose} canClose={canClose} onRefresh={onRefresh} canRefresh={canRefresh}>
                            Subscription {unifiedSubscriptionKey(userSubscriptionState.selected)}
                        </ActionCardTitle>
                        <Card.Subtitle>
                            {usersState.selected ? <>User {unifiedUserKey(usersState.selected)}</> : null}
                        </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                        <UnifiedSubscriptionDetailsView unifiedSubscription={userSubscriptionState.selected} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

const SelectedWebSessionView = () => {
    const webSessionState = useAppSelector(selectUserWebSessionsState)
    const dispatch = useAppDispatch()
    const onClose = () => dispatch(selectWebSession(null))
    const canClose = webSessionState.selected !== null
    if (webSessionState.selected === null) {
        return null;
    }
    return (
        <Row className='mb-2'>
            <Col>
                <Card>
                    <Card.Header>
                        <ActionCardTitle onClose={onClose} canClose={canClose}>
                            Web Session {unifiedWebSessionKey(webSessionState.selected)}
                        </ActionCardTitle>
                    </Card.Header>
                    <Card.Body>
                        <WebSessionDetailsView unifiedWebSession={webSessionState.selected} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

const SelectedContractView = () => {
    const usersState = useAppSelector(selectUsersState)
    const userContractsState = useAppSelector(selectUserContractsState)
    const dispatch = useAppDispatch()
    const onClose = () => dispatch(selectContract(null))
    const canClose = userContractsState.selected !== null
    if (userContractsState.selected === null) {
        return null;
    }
    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <ActionCardTitle onClose={onClose} canClose={canClose}>
                            Contract {unifiedContractKey(userContractsState.selected)}
                        </ActionCardTitle>
                        <Card.Subtitle>
                            {usersState.selected ? <>User {unifiedUserKey(usersState.selected)}</> : null}
                        </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                        <UnifiedContractDetailsView unifiedContract={userContractsState.selected} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export const UsersDashboard = () => {
    const usersState = useAppSelector(selectUsersState)
    const dispatch = useAppDispatch()
    useRequest(() => {
        if (usersState.query !== null) {
            console.log('query', usersState.query)
            dispatch(loadUsersAsync(usersState.query))
        }
    }, usersState.status, [usersState.query])
    useEffect(() => {
        if (usersState.selected === null) {
            dispatch(selectSubscription(null))
            dispatch(selectContract(null))
        }
    }, [usersState.selected])
    return (
        <Container>
            <Row className="mb-2">
                <Col>
                    <QueryLine />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <UsersListView />
                </Col>
                <Col sm={4}>
                    <SelectedUserView />
                </Col>
                <Col sm={4}>
                    <SelectedWebSessionView />
                    <SelectedSubscriptionView />
                    <SelectedContractView />
                </Col>
            </Row>
        </Container>
    )
}