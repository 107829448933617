import {Api} from "./Api";
import {UserDto} from "../Schema/models/UserDto";
import {UserScopeDto} from "../Schema/models/UserScopeDto";

export class ManageApi extends Api {

    async queryManageUsers(): Promise<UserDto[]> {
        return this.newCall<UserDto[]>('manage/users/query')
            .fetchJson()
    }

    async queryManageUserScopes(user_id: number): Promise<UserScopeDto[]> {
        return this.newCall<UserScopeDto[]>(`manage/users/${user_id}/scopes/query`)
            .fetchJson()
    }
}