import { useEffect, useState } from "react";
import { Card, ListGroup, ListGroupItem, Spinner } from "react-bootstrap";
import { ChevronDown } from "react-bootstrap-icons";
import { UnifiedWebSession, unifiedWebSessionKey } from "../../Core/Model/UnifiedWebSession";
import { WebSessionTimelineEntryDto } from "../../Core/Schema/models/WebSessionTimelineEntryDto";
import { useAppDispatch, useAppSelector } from "../../Core/Store/hooks";
import { changeWebSessionTimelineQuery, loadWebWebSessionTimelineAsync, selectWebSessionTimelineEntry, selectWebSessionTimelineState } from "../../Core/Store/webSessionTimelineSlice";
import { useRequest } from "../../Core/useRequest";
import { ErrorView } from "../ErrorView";
import { WebSessionTimelineRowView } from "../WebSessionTimelineRow/WebSessionTimelineRowView";
import styles from './WebSessionDetailsView.module.css'

export const WebSessionDetailsView = ({ unifiedWebSession }: { unifiedWebSession: UnifiedWebSession }) => {
    const sessionKey = unifiedWebSessionKey(unifiedWebSession)

    const [isCollapsed, setCollapsed] = useState(false)
    const dispatch = useAppDispatch()
    const webSessionTimelineState = useAppSelector(selectWebSessionTimelineState)
    useEffect(() => {
        console.log(webSessionTimelineState.query?.web_session_id, sessionKey)
        if (webSessionTimelineState.query?.web_session_id !== sessionKey) {
            dispatch(changeWebSessionTimelineQuery({ web_session_id: sessionKey, model: unifiedWebSession.model }))
        }
    }, [webSessionTimelineState.query, sessionKey])
    useRequest(() => {
        if (webSessionTimelineState.status === 'refresh_required' || (webSessionTimelineState.status === 'idle' && webSessionTimelineState.timeline.length === 0)) {
            if (webSessionTimelineState.query !== null) {
                dispatch(loadWebWebSessionTimelineAsync(webSessionTimelineState.query))
            }
        }
    }, webSessionTimelineState.status, [webSessionTimelineState.query])
    const actualEntries = webSessionTimelineState.query?.web_session_id === sessionKey ?
        webSessionTimelineState.timeline : []
    const onSelected = (eventKey: string | null) => {
        if (eventKey !== null) {
            const entry = actualEntries.find(e => e.id === eventKey)
            if (entry !== undefined) {
                dispatch(selectWebSessionTimelineEntry(entry))
            }
        }
    }
    return (
        <Card>
            <Card.Header>
                <Card.Title className={styles.alertTitle}>
                    <div className={styles.collapseTitle}>
                        <div className={styles.titleWithProgress}>
                            <span>Timeline</span>
                            {webSessionTimelineState.status === 'loading' && <span>
                                <Spinner size='sm' />
                            </span>}
                        </div>
                        <ChevronDown onClick={() => setCollapsed(!isCollapsed)} className={styles.collapseKey}/>
                    </div>
                </Card.Title>
            </Card.Header>
            <ErrorView {...webSessionTimelineState} />
            {!isCollapsed && <ListGroup activeKey={webSessionTimelineState.selected ? sessionKey : null} onSelect={onSelected}>
                {actualEntries.map(
                    (entry: WebSessionTimelineEntryDto) => {
                        const highlighted = webSessionTimelineState.selected?.references?.includes(entry.id) === true ||
                            webSessionTimelineState.selected?.id === entry.id === true
                        return (
                            <ListGroupItem style={{ borderRadius: 0, background: highlighted ? 'rgba(255, 215, 0, 0.5)' : '' }} action key={entry.id} eventKey={entry.id}>
                                <WebSessionTimelineRowView highlighted={highlighted} entry={entry} />
                            </ListGroupItem>
                        )
                    }
                )}
            </ListGroup>}
        </Card>
    )
}