import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {getStorage, setStorage, StorageKey} from "../Storage/storage";
import {
    UserAuthorization,
    UserAuthorizationFromJSON,
    UserAuthorizationToJSON
} from "../Schema/models/UserAuthorization";
import {AuthorizedUser, newAuthorizedUser} from "../Model/AuthorizedUser";

function getStorageUser() {
    try {
        const user = getStorage(StorageKey.USER)
        if (user === null) {
            return null
        }
        return newAuthorizedUser(UserAuthorizationFromJSON(JSON.parse(user)));
    } catch (e) {
        console.error(e)
        setStorage(StorageKey.USER, null)
        return null
    }
}

function setStorageUser(user: UserAuthorization | null) {
    setStorage(StorageKey.USER, JSON.stringify(UserAuthorizationToJSON(user)))
}

interface AuthState {
    user: AuthorizedUser | null,
    challenge: string | null
    logout: boolean
}

const initialState: AuthState = {
    challenge: null,
    user: getStorageUser(),
    logout: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setChallenge: (state, action: PayloadAction<string | null>) => {
            state.challenge = action.payload
        },
        setLogout: (state, action: PayloadAction<boolean>) => {
            state.logout = action.payload
            state.challenge = action.payload ? null : state.challenge

            setStorage(StorageKey.USER, null)
        },
        setUser: (state, action: PayloadAction<UserAuthorization | null>) => {
            state.user = action.payload ? newAuthorizedUser(action.payload) : null;
            state.challenge = null
            state.logout = false

            setStorageUser(action.payload)
        }
    }
})

export default authSlice.reducer;
export const { setChallenge, setLogout, setUser } = authSlice.actions;
export const selectAuthState = (state: RootState) => state.auth;