import {WaActivityRecordDto} from "../Schema/models/WaActivityRecordDto";
import {VisitNoteDto} from "../Schema/models/VisitNoteDto";

export type UnifiedActivity = {
    type: 'WaActivityRecordDto',
    model: string,
    activity: WaActivityRecordDto
} | {
    type: 'VisitNoteDto',
    model: string,
    activity: VisitNoteDto
}

export const unifiedActivityKey = (unifiedActivity: UnifiedActivity) => {
    switch (unifiedActivity.type) {
        case "VisitNoteDto": return unifiedActivity.activity.id
        case "WaActivityRecordDto": return unifiedActivity.activity.activity_id
        default: return "never"
    }
}

export const unifiedActivityStatus = (unifiedActivity: UnifiedActivity) => {
    switch (unifiedActivity.type) {
        case "VisitNoteDto": return unifiedActivity.activity.is_online
        case "WaActivityRecordDto": return unifiedActivity.activity.is_online
    }
}