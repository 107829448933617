import {WaUserContractDto} from "../Schema/models/WaUserContractDto";
import {PaymentDto} from "../Schema/models/PaymentDto";

export type UnifiedContract = {
    type: "WaUserContractDto",
    model: string,
    contract: WaUserContractDto
} | {
    type: "PaymentDto",
    model: string,
    contract: PaymentDto
}

export const unifiedContractKey = (unifiedContract: UnifiedContract): any => {
    switch (unifiedContract.type) {
        case "WaUserContractDto": return unifiedContract.contract.contract_id
        case "PaymentDto": return unifiedContract.contract.id
        default: return "never"
    }
}