/* tslint:disable */
/* eslint-disable */
/**
 * WaExplorer
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAuthorization } from './UserAuthorization';
import {
    UserAuthorizationFromJSON,
    UserAuthorizationFromJSONTyped,
    UserAuthorizationToJSON,
} from './UserAuthorization';

/**
 * UserLoginResponse(type: waexplorer.api.model.manage_dto.UserLoginResponseType, message: str, authorization: Optional[waexplorer.api.model.manage_dto.UserAuthorization], challenge: Optional[str])
 * @export
 * @interface UserLoginResponse
 */
export interface UserLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponse
     */
    type: UserLoginResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponse
     */
    message: string;
    /**
     * 
     * @type {UserAuthorization}
     * @memberof UserLoginResponse
     */
    authorization?: UserAuthorization;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponse
     */
    challenge?: string;
}


/**
 * @export
 */
export const UserLoginResponseTypeEnum = {
    logged_in: 'logged_in',
    unauthenticated: 'unauthenticated',
    challenge_required: 'challenge_required',
    bad_request: 'bad_request'
} as const;
export type UserLoginResponseTypeEnum = typeof UserLoginResponseTypeEnum[keyof typeof UserLoginResponseTypeEnum];


/**
 * Check if a given object implements the UserLoginResponse interface.
 */
export function instanceOfUserLoginResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function UserLoginResponseFromJSON(json: any): UserLoginResponse {
    return UserLoginResponseFromJSONTyped(json, false);
}

export function UserLoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'message': json['message'],
        'authorization': !exists(json, 'authorization') ? undefined : UserAuthorizationFromJSON(json['authorization']),
        'challenge': !exists(json, 'challenge') ? undefined : json['challenge'],
    };
}

export function UserLoginResponseToJSON(value?: UserLoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'message': value.message,
        'authorization': UserAuthorizationToJSON(value.authorization),
        'challenge': value.challenge,
    };
}

