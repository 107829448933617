/* tslint:disable */
/* eslint-disable */
/**
 * WaExplorer
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAuthorization } from './UserAuthorization';
import {
    UserAuthorizationFromJSON,
    UserAuthorizationFromJSONTyped,
    UserAuthorizationToJSON,
} from './UserAuthorization';

/**
 * UserRefreshResponse(type: waexplorer.api.model.manage_dto.UserRefreshResponseType, authorization: Optional[waexplorer.api.model.manage_dto.UserAuthorization])
 * @export
 * @interface UserRefreshResponse
 */
export interface UserRefreshResponse {
    /**
     * 
     * @type {string}
     * @memberof UserRefreshResponse
     */
    type: UserRefreshResponseTypeEnum;
    /**
     * 
     * @type {UserAuthorization}
     * @memberof UserRefreshResponse
     */
    authorization?: UserAuthorization;
}


/**
 * @export
 */
export const UserRefreshResponseTypeEnum = {
    unauthenticated: 'unauthenticated',
    password_mismatch: 'password_mismatch',
    logged_in: 'logged_in'
} as const;
export type UserRefreshResponseTypeEnum = typeof UserRefreshResponseTypeEnum[keyof typeof UserRefreshResponseTypeEnum];


/**
 * Check if a given object implements the UserRefreshResponse interface.
 */
export function instanceOfUserRefreshResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function UserRefreshResponseFromJSON(json: any): UserRefreshResponse {
    return UserRefreshResponseFromJSONTyped(json, false);
}

export function UserRefreshResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRefreshResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'authorization': !exists(json, 'authorization') ? undefined : UserAuthorizationFromJSON(json['authorization']),
    };
}

export function UserRefreshResponseToJSON(value?: UserRefreshResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'authorization': UserAuthorizationToJSON(value.authorization),
    };
}

