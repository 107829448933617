import {useAppSelector} from "../../Core/Store/hooks";
import {selectAuthState} from "../../Core/Store/authSlice";
import {Navigate, Outlet, useMatch, useParams} from "react-router-dom";
import {UserProvider} from "./UserContext";
import {haveAccess} from "../../Core/Model/AuthorizedUser";

export const AuthGuard = () => {
    const authState = useAppSelector(selectAuthState)
    const match = useMatch("/dashboard/:dashboardId")
    if (authState.user === null) {
        return <Navigate to='/auth/login' replace />
    }
    if (match !== null && match.params.dashboardId !== undefined) {
        const dashboardId = match.params.dashboardId
        if (!haveAccess(authState.user, dashboardId)) {
            return <Navigate to={'/dashboard'} />
        }
    }
    return (
        <UserProvider user={authState.user}>
            <Outlet />
        </UserProvider>
    )
}