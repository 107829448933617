import {DuplicateDto} from "../../Core/Schema/models/DuplicateDto";
import {Button, Card, ListGroup, Modal, ProgressBar, Spinner} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../Core/Store/hooks";
import {
    deleteDuplicateAsync,
    selectDuplicateDeleteState,
    setDuplicateToDelete
} from "../../Core/Store/duplicateDeleteSlice";
import {refreshSubscriptions, selectUserSubscriptionsState} from "../../Core/Store/userSubscriptionsSlice";
import {unifiedSubscriptionKey} from "../../Core/Model/UnifiedSubscription";
import {UnifiedSubscriptionRowView} from "../SubscriptionRow/UnifiedSubscriptionRowView";
import {refreshUser} from "../../Core/Store/usersSlice";
import {UnifiedTcToken} from "../../Core/Model/UnifiedTcToken";
import {UnifiedTcTokenRowView} from "../TcTokenRow/UnifiedTcTokenRowView";
import {refreshPhoneTcTokens} from "../../Core/Store/phoneTcTokensSlice";

export type DeleteInfo = {
    name: 'Subscription',
    duplicate: DuplicateDto
} | {
    name: 'TcToken',
    tcToken: UnifiedTcToken
}

export interface DuplicateDeleteDialogProps {
    info: DeleteInfo
    id: any
    model: string
}

const DuplicateDeleteDialogView = (props: DuplicateDeleteDialogProps) => {
    const userSubscriptionState = useAppSelector(selectUserSubscriptionsState)
    switch (props.info.name) {
        case 'TcToken': {
            const currentView = <UnifiedTcTokenRowView unifiedTcToken={props.info.tcToken} showDelete={false} />
            return (
                <ListGroup>
                    <Card>
                        <Card.Header>
                            <Card.Title>TcToken</Card.Title>
                            <Card.Subtitle className='text-danger'>Will be disabled</Card.Subtitle>
                        </Card.Header>
                        <Card.Body>
                            {currentView}
                        </Card.Body>
                    </Card>
                </ListGroup>
            )
        }
        case 'Subscription': {
            const duplicate = props.info.duplicate
            const current = userSubscriptionState.subscriptions?.find(e => unifiedSubscriptionKey(e).toString() === props.id.toString())
            const original = userSubscriptionState.subscriptions?.find(e => unifiedSubscriptionKey(e).toString() === duplicate.original_id.toString())
            const currentView = current ?
                <UnifiedSubscriptionRowView unifiedSubscription={current} canDelete={false} onDelete={() => {}} />
                : <div>ID: {props.id}</div>
            const originalView = original ?
                <UnifiedSubscriptionRowView unifiedSubscription={original} canDelete={false} onDelete={() => {}} />
                : <div>Original {props.info.name} ID: <b>{duplicate.original_id}</b></div>
            return (
                <ListGroup>
                    <Card>
                        <Card.Header>
                            <Card.Title>Duplicate</Card.Title>
                            <Card.Subtitle className='text-danger'>Will be removed</Card.Subtitle>
                        </Card.Header>
                        <Card.Body>
                            {currentView}
                        </Card.Body>
                    </Card>
                    <Card className='mt-2'>
                        <Card.Header>
                            <Card.Title>Original {props.info.name}</Card.Title>
                            <Card.Subtitle className='text-success'>Will stay intact</Card.Subtitle>
                        </Card.Header>
                        <Card.Body>
                            {originalView}
                        </Card.Body>
                    </Card>
                </ListGroup>
            )
        }
    }
    return null;
}

const DuplicateDeleteDialogControls = (props: DuplicateDeleteDialogProps) => {
    const duplicateDeleteState = useAppSelector(selectDuplicateDeleteState)
    const dispatch = useAppDispatch()
    const onCancel = () => {
        dispatch(setDuplicateToDelete(null));
    }
    const onConfirm = () => {
        switch (props.info.name) {
            case 'Subscription': {
                dispatch(deleteDuplicateAsync({ duplicateKey: props.info.duplicate.key, model: props.model }))
                break;
            }
            case 'TcToken': {
                dispatch(deleteDuplicateAsync({ duplicateKey: props.info.tcToken.tcToken.delete_key!, model: props.model }))
                break;
            }
        }
    }
    const onClose = () => {
        switch (props.info.name) {
            case "Subscription": {
                dispatch(refreshSubscriptions())
                onCancel()
                break;
            }
            case "TcToken": {
                dispatch(refreshPhoneTcTokens())
                onCancel()
                break;
            }
        }
    }

    return duplicateDeleteState.status === 'loading' ?
        <>
            <Spinner variant='primary' />
        </> : duplicateDeleteState.response !== null ? <>
            <>
                <div>
                    <div>{duplicateDeleteState.response ? 'Delete successful' : 'Duplicate has not been deleted'}</div>
                    <div className='text-danger'>{duplicateDeleteState.errorMessage}</div>
                </div>
                <Button onClick={onClose} variant='outline-danger'>Close</Button>
            </>
        </> : <>
            <Button onClick={onConfirm} variant='danger'>Confirm delete</Button>
            <Button onClick={onCancel} variant='outline-primary'>Cancel delete</Button>
        </>
}

export const DuplicateDeleteDialog = (props: DuplicateDeleteDialogProps) => {
    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title>Delete {props.info.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DuplicateDeleteDialogView {...props} />
            </Modal.Body>
            <Modal.Footer>
                <DuplicateDeleteDialogControls {...props} />
            </Modal.Footer>
        </Modal>
    )
}

export const DuplicateDeleteModal = () => {
    const duplicateDeleteState = useAppSelector(selectDuplicateDeleteState)
    if (duplicateDeleteState.current === null) {
        return null
    } else {
        return <DuplicateDeleteDialog {...duplicateDeleteState.current} />
    }
}