import {WaTcTokenDto} from "../Schema/models/WaTcTokenDto";
import {TcTokenDto} from "../Schema/models/TcTokenDto";
import {Variant} from "react-bootstrap/types";

export type UnifiedTcToken = {
    type: 'WaTcTokenDto',
    model: string,
    tcToken: WaTcTokenDto
    original: WaTcTokenDto
} | {
    type: 'TcTokenDto',
    model: string,
    tcToken: TcTokenDto,
    original: TcTokenDto
}

export const unifiedTcTokenKey = (unifiedTcToken: UnifiedTcToken): any => {
    switch (unifiedTcToken.type) {
        case "TcTokenDto": return unifiedTcToken.tcToken.tc_token_id
        case "WaTcTokenDto": return unifiedTcToken.tcToken.tc_token_id
    }
}

export const unifiedTcTokenTypeName = (type: string): string => {
    switch (type) {
        case "WEB_ACQUIRED": return "Web Acquired";
        case "COMPANION_ACQUIRED": return "Companion Acquired";
        case "TRUSTED_BOT_ACQUIRED": return "Trusted Bot Acquired";
        case "AUTO_COMPANION": return "AUTO";
        default: return type;
    }
}

export const unifiedTcTokenTypeVariant = (type: string): Variant => {
    switch (type) {
        case "WEB_ACQUIRED": return 'success';
        case "COMPANION_ACQUIRED": return "primary";
        case "TRUSTED_BOT_ACQUIRED": return "dark";
        case "AUTO_COMPANION": return "warning";
        default: return 'primary';
    }
}

export const unifiedTcTokenTypeDescription = (type: string): string => {
    switch (type) {
        case "WEB_ACQUIRED": return "TcToken is acquired from User's device during QR Web Session";
        case "COMPANION_ACQUIRED": return "TcToken is acquired by User's companion bot automatically";
        case "TRUSTED_BOT_ACQUIRED": return "TcToken is acquired by our Bot for User's phone number after QR Web Session";
        case "AUTO_COMPANION": return "TcToken is created for companion to test its ability to track User contacts";
        default: return type;
    }
}