import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {UserLoginRequest} from "../Schema/models/UserLoginRequest";
import {AuthApi} from "../Api/AuthApi";
import {RootState} from "./store";
import {UserLoginResponse} from "../Schema/models/UserLoginResponse";
import {apiProvider} from "./apiProvider";

interface LoginState {
    response: UserLoginResponse | null
    status: 'idle' | 'loading'
    errorMessage: string | null
}

const initialState: LoginState = {
    status: 'idle',
    response: null,
    errorMessage: null
}

export const loginAsync = createAsyncThunk(
    'login/loginAsync',
    apiProvider(AuthApi, (request: UserLoginRequest, api: AuthApi) => {
        return api.userLogin(request)
    })
)

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        clearLogin: (state) => {
            state.response = null
        }
    },
    extraReducers: builder => {
        builder.addCase(loginAsync.pending, (state) => {
            state.status = 'loading'
            state.response = null
            state.errorMessage = null
        }).addCase(loginAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.response = action.payload
            state.errorMessage = null
        }).addCase(loginAsync.rejected, (state, action) => {
            state.status = 'idle'
            state.response = null
            state.errorMessage = action.error.message ?? "Error occurred while performing login"
        })
    }
})

export default loginSlice.reducer;
export const { clearLogin } = loginSlice.actions;
export const selectLoginState = (state: RootState) => state.login;