import { Android2, ArrowClockwise, ArrowsAngleExpand, ArrowsMove, Dot, KeyFill, Plus, PlusCircle, PlusLg, QrCode } from "react-bootstrap-icons";
import { WebSessionTimelineEntryDto } from "../../Core/Schema/models/WebSessionTimelineEntryDto"
import styles from './WebSessionTimelineRowView.module.css'

export const WebSessionTimelineRowView = ({ entry, highlighted }: { entry: WebSessionTimelineEntryDto, highlighted: boolean }) => {
    const isCreate = entry.tags.includes("Create");
    const isUpdate = entry.tags.includes("Update");
    const typeIcon = entry.tags.includes("Token") ?
        <KeyFill /> :
        entry.tags.includes("Session") ?
        <QrCode /> :
        entry.tags.includes("Client") ?
        <Android2 /> :
        null;
    return (
        <div>
            <div className={styles.time}>{entry.time.toString()}</div>
            <div className={styles.row}>
                <div className={styles.icons}>
                    {isCreate ? <PlusLg color='green'/> : isUpdate ? <ArrowClockwise color='green' /> : <Dot />}
                    {typeIcon}
                </div>
                <div>
                    <div>{entry.message}</div>
                    <div className={styles.description}>{entry.description}</div>
                </div>
            </div>
        </div>
    )
}