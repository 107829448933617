import {Api} from "./Api";
import {PhoneTcTokensQuery} from "../Model/PhoneTcTokensQuery";
import {UnifiedTcToken} from "../Model/UnifiedTcToken";
import {Typed} from "../Schema/models/Typed";
import {WaTcTokenDto} from "../Schema/models/WaTcTokenDto";
import {TcTokenDto} from "../Schema/models/TcTokenDto";
import {PhoneSubscriptionQuery} from "../Model/SubscriptionQuery";
import {PhoneInfo} from "../Model/PhoneInfo";
import {SubscriptionsApi} from "./SubscriptionsApi";
import {UnifiedSubscription} from "../Model/UnifiedSubscription";

export class PhoneApi extends Api {

    static async unifyTypedTcToken(typed: Typed[]): Promise<UnifiedTcToken[]> {
        const tcTokens: UnifiedTcToken[] = []
        const originals: {[key: string]: TcTokenDto | WaTcTokenDto} = {}

        for (let i = typed.length - 1; i >= 0; i--) {
            const typedTcToken = typed[i]
            switch (typedTcToken.type) {
                case "WaTcTokenDto": {
                    const current = typedTcToken.data as WaTcTokenDto
                    if (originals[current.token_value] === undefined) {
                        originals[current.token_value] = current
                    }
                    break
                }
                case "TcTokenDto": {
                    const current = typedTcToken.data as TcTokenDto
                    if (originals[current.token] === undefined) {
                        originals[current.token] = current
                    }
                    break
                }
            }
        }

        for (const typedTcToken of typed) {
            switch (typedTcToken.type) {
                case "WaTcTokenDto": {
                    const current = typedTcToken.data as WaTcTokenDto
                    const original = originals[current.token_value]
                    tcTokens.push({ type: typedTcToken.type, model: typedTcToken.model, tcToken: current, original: (original || current) as WaTcTokenDto })
                    break
                }
                case "TcTokenDto": {
                    const current = typedTcToken.data as TcTokenDto
                    const original = originals[current.token]
                    tcTokens.push({ type: typedTcToken.type, model: typedTcToken.model, tcToken: current, original: (original || current) as TcTokenDto })
                    break
                }
                default: {
                    throw new Error(`Could not transform tc token ${typedTcToken.type}@${typedTcToken.model} to unified tc token`)
                }
            }
        }
        return tcTokens
    }

    static phonesQueryToParam(request: PhoneSubscriptionQuery): object {
        return { phone: request.phone, model: request.model }
    }

    static async phonesInfo(subscriptions: UnifiedSubscription[]): Promise<PhoneInfo[]> {
        const infos: {[key: string]: PhoneInfo} = {}
        const subscriptionPhone = (s: UnifiedSubscription): string => {
            switch (s.type) {
                case "SubscriptionPhoneDto": return s.subscription.phone_num.toString()
                case "WaUserSubscriptionDto": return s.subscription.phone_num
                default: return "unknown"
            }
        }
        const subscriptionType = (s: UnifiedSubscription): string => {
            switch (s.type) {
                case "SubscriptionPhoneDto": return "whatsapp"
                case "WaUserSubscriptionDto": return s.subscription.type.subscription_type_name
                default: return "unknown"
            }
        }
        for (const subscription of subscriptions) {
            const phone = subscriptionPhone(subscription)
            const type = subscriptionType(subscription)

            const key = `${phone}.${type}`
            if (infos[key] === undefined) {
                infos[key] = {
                    phone: phone,
                    subscriptions: [subscription],
                    model: subscription.model,
                    subscription_type_name: type,
                    active_count: subscription.subscription.is_active ? 1 : 0,
                    business_count: subscription.subscription.is_business ? 1 : 0,
                    tracking_count: subscription.subscription.is_tracking ? 1 : 0,
                    users: new Set([subscription.subscription.user_id.toString()])
                }
            } else {
                infos[key].subscriptions.push(subscription)
                infos[key].users.add(subscription.subscription.user_id.toString())
                if (subscription.subscription.is_active) {
                    infos[key].active_count += 1
                }
                if (subscription.subscription.is_business) {
                    infos[key].business_count += 1
                }
                if (subscription.subscription.is_tracking) {
                    infos[key].tracking_count += 1
                }
            }
        }
        return Object.values(infos)
    }

    async queryPhoneTcTokens(query: PhoneTcTokensQuery): Promise<UnifiedTcToken[]> {
        return this.newCall<Typed[]>(`phones/${query.phone}/${query.model}/tc_tokens/query`)
            .fetchJson()
            .then(e => PhoneApi.unifyTypedTcToken(e))
    }

    async queryPhones(query: PhoneSubscriptionQuery): Promise<PhoneInfo[]> {
        return this.newCall<Typed[]>(`phones/query`)
            .withQueries(PhoneApi.phonesQueryToParam(query))
            .fetchJson()
            .then(e => SubscriptionsApi.unifyTypedSubscriptions(e))
            .then(e => PhoneApi.phonesInfo(e))
    }
}