import {
    Card,
    Col,
    Container,
    Dropdown,
    Form,
    InputGroup,
    ListGroup,
    ListGroupItem,
    Row,
    Spinner
} from "react-bootstrap";

import styles from './PhonesDashboard.module.css'
import {loadModelsAsync, modelsValidation, selectModelsState} from "../../../Core/Store/modelsSlice";
import {ModelMetadata} from "../../../Core/Schema/models/ModelMetadata";
import React, {createRef, FormEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../Core/Store/hooks";
import {UsersQuery, usersQuery} from "../../../Core/Model/UsersQuery";
import {useRequest} from "../../../Core/useRequest";
import {ErrorView} from "../../../Views/ErrorView";
import {
    pushQueryHistory,
    QueryHistoryType,
    selectQueryHistoryState
} from "../../../Core/Store/queryHistorySlice";
import {ClockHistory} from "react-bootstrap-icons";
import {PhoneSubscriptionQuery} from "../../../Core/Model/SubscriptionQuery";
import {changePhoneQuery, loadPhonesAsync, selectPhone, selectPhonesState} from "../../../Core/Store/phonesSlice";
import {PhoneInfo} from "../../../Core/Model/PhoneInfo";
import {PhoneInfoRow} from "../../../Views/PhoneInfoRow/PhoneInfoRow";

const QueryLine = () => {
    const queryRef = createRef<HTMLInputElement>()
    const modelsRef = createRef<HTMLSelectElement>()
    const dispatch = useAppDispatch()
    const modelsState = useAppSelector(selectModelsState)
    const queryHistoryState = useAppSelector(selectQueryHistoryState)
    const queryDisabled = modelsState.status !== 'idle' && modelsState.status !== 'refresh_required'
    useRequest(() => {
        if (modelsState.status === 'refresh_required' || (modelsState.status === 'idle' && modelsState.models === null)) {
            dispatch(loadModelsAsync())
        }
    }, modelsState.status, [])
    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const target = event.target as HTMLFormElement
        const textInput = target.elements.namedItem('query') as HTMLInputElement
        const modelInput = target.elements.namedItem('model') as HTMLInputElement
        const text = textInput.value
        const model = modelInput.value
        try {
            const query: PhoneSubscriptionQuery = { model: model, phone: text }
            dispatch(pushQueryHistory({ type: QueryHistoryType.PHONES, date: new Date().getTime(), model: model, value: text }))
            dispatch(modelsValidation(null))
            dispatch(changePhoneQuery(query))
        } catch (e: any) {
            if (e instanceof Error) {
                dispatch(modelsValidation(e.message))
            } else {
                dispatch(modelsValidation('Could not validate query'))
            }
        }
    }
    const onHistorySelect = (eventKey: string | null) => {
        if (eventKey === null || !eventKey.match(/\d+/)) {
            return
        }
        const index = Number(eventKey)
        const entry = index >= 0 && index < queryHistoryState.phones.length ? queryHistoryState.phones[index] : null
        if (entry !== null) {
            const currentQuery = queryRef.current
            const currentModels = modelsRef.current
            if (currentQuery && currentModels) {
                currentQuery.value = entry.value
                currentModels.selectedIndex = modelsState.models?.findIndex(e => e.name === entry.model) ?? 0
            }
        }
    }
    return (
        <Form onSubmit={onSubmit}>
            <Form.Group as={InputGroup} className={styles.queryLine} hasValidation>
                <Form.Select ref={modelsRef} name='model' isInvalid={modelsState.status !== 'refresh_required' && modelsState.loadMessage !== null} disabled={queryDisabled}>
                    {modelsState.models !== null && modelsState.models.length > 0 ? modelsState.models?.map(
                        (model: ModelMetadata) => {
                            return (
                                <option key={model.name} value={model.name}>
                                    {model.display_name}
                                </option>
                            )
                        }
                    ) : <option disabled>No models</option>}
                </Form.Select>
                <Dropdown onSelect={onHistorySelect}>
                    <Dropdown.Toggle as='div' className='form-control' variant='outline-dark'>
                        <ClockHistory />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {queryHistoryState.phones.length === 0 &&
                            <Dropdown.Item disabled>
                                No history yet
                            </Dropdown.Item>}
                        {queryHistoryState.phones.map((entry, idx) => {
                            const model = modelsState.models?.find(e => e.name === entry.model)
                            const delta = new Date().getTime() - entry.date
                            const hours = delta / (1000 * 60 * 60)
                            const days = hours / 24
                            return (
                                <Dropdown.Item eventKey={idx} key={entry.date.toString()}>
                                    <div>
                                        <div className={styles.dateLine}>{days > 1 ? `${days.toFixed(0)} days ago` : (hours < 1 ? `Less than hour ago` : `${hours.toFixed(0)} hours ago`)}</div>
                                        {entry.model && <div className={styles.modelLine}>{model?.display_name ?? entry.model}</div>}
                                        <div>{entry.value}</div>
                                    </div>
                                </Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <Form.Control ref={queryRef} name='query' isInvalid={modelsState.validationMessage !== null} disabled={queryDisabled}></Form.Control>
                <Form.Control disabled={queryDisabled} type='submit' value='Query'></Form.Control>
                {modelsState.status !== 'refresh_required' &&
                    <Form.Control.Feedback type='invalid'>{modelsState.loadMessage ?? modelsState.validationMessage}</Form.Control.Feedback>}
            </Form.Group>
        </Form>
    )
}

const QueryView = ({ query }: { query: UsersQuery | null }) => {
    if (query === null) {
        return (
            <span>Query aggregated information about phone</span>
        )
    }
    return (
        <div>
            {query.token ?? query.user_id} @ {query.model ?? 'Any'}
        </div>
    )
}

const PhonesListView = () => {
    const dispatch = useAppDispatch()
    const phonesState = useAppSelector(selectPhonesState)
    const onPhoneSelect = (eventKey: string | null) => {
        console.log('onSelect', eventKey)
        if (eventKey === null) {
            return
        }
        const phone = phonesState.phones?.find(e => eventKey === e.phone)
        if (phone === undefined) {
            console.log('no phone with key', eventKey)
            return
        }
        dispatch(selectPhone(phone))
    }
    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>
                        <Card.Title className={styles.usersTitle}>
                            <div className={styles.titleWithProgress}>
                                <span>Phones</span>
                                {phonesState.status === 'loading' && <span>
                                    <Spinner size='sm' />
                                </span>}
                            </div>
                        </Card.Title>
                        <Card.Subtitle>
                            <QueryView query={phonesState.query} />
                        </Card.Subtitle>
                    </Card.Header>
                    <ErrorView {...phonesState} />
                    <ListGroup activeKey={phonesState.selected ? phonesState.selected.phone : undefined} onSelect={onPhoneSelect}>
                        {phonesState.phones?.map(
                            (phone: PhoneInfo) => {
                                const key = phone.phone
                                return (
                                    <ListGroupItem style={{ borderRadius: 0 }} action key={key} eventKey={key}>
                                        <PhoneInfoRow phoneInfo={phone} />
                                    </ListGroupItem>
                                )
                            }
                        )}
                    </ListGroup>
                    {phonesState.phones && <Card.Footer>
                        <div>{phonesState.phones.length ?? 0} phone(s)</div>
                    </Card.Footer>}
                </Card>
            </Col>
        </Row>
    )
}

export const PhonesDashboard = () => {
    const phonesState = useAppSelector(selectPhonesState)
    const dispatch = useAppDispatch()
    useRequest(() => {
        if (phonesState.query !== null) {
            console.log('query', phonesState.query)
            dispatch(loadPhonesAsync(phonesState.query))
        }
    }, phonesState.status, [phonesState.query])
    useEffect(() => {
        if (phonesState.selected === null) {
            dispatch(selectPhone(null))
        }
    }, [phonesState.selected])
    return (
        <Container>
            <Row className="mb-2">
                <Col>
                    <QueryLine />
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <PhonesListView />
                </Col>
                <Col sm={4}>
                </Col>
                <Col sm={4}>
                </Col>
            </Row>
        </Container>
    )
}