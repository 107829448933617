import {Button, Card, Col, Container, Form, FormControl, FormGroup, Row, Spinner} from "react-bootstrap";
import React, {FormEvent, useEffect, useState} from "react";
import styles from './Login.module.css'
import {useAppDispatch, useAppSelector} from "../../Core/Store/hooks";
import {clearLogin, loginAsync, selectLoginState} from "../../Core/Store/loginSlice";
import {UserLoginRequest} from "../../Core/Schema/models/UserLoginRequest";
import {UserLoginResponseTypeEnum} from "../../Core/Schema/models/UserLoginResponse";
import {setUser} from "../../Core/Store/authSlice";
import {useNavigate} from "react-router-dom";
import {log} from "util";

export interface FieldValidity {
    valid: boolean,
    message: string
}

interface Validity {
    username: FieldValidity | null
    password: FieldValidity | null,
    valid: boolean
}

const initialValidity = {
    username: null,
    password: null,
    valid: false
}

function validateUsername(username: string): FieldValidity {
    if (username.length < 3) {
        return { valid: false, message: 'Username too short' }
    }
    return { valid: true, message: 'Ok' }
}

function validatePassword(password: string): FieldValidity {
    if (password.length < 3) {
        return { valid: false, message: 'Password too short' }
    }
    return { valid: true, message: 'Ok' }
}

export const Login = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const loginState = useAppSelector(selectLoginState)
    const [validity, setValidity] = useState<Validity>(initialValidity)
    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const form = event.currentTarget
        const { username, password } = form
        const usernameText = (username?.value ?? '').trim()
        const passwordText = (password?.value ?? '').trim()

        const usernameValidity = validateUsername(usernameText)
        const passwordValidity = validatePassword(passwordText)
        const formValidity = usernameValidity.valid && passwordValidity.valid
        setValidity({ username: usernameValidity, password: passwordValidity, valid: formValidity })
        console.log(usernameValidity, passwordValidity, validity)

        if (!formValidity) {
            return
        }

        const request: UserLoginRequest = { username: usernameText, password: passwordText }
        dispatch(loginAsync(request))
    }
    useEffect(() => {
        if (loginState.response !== null && loginState.response.type === UserLoginResponseTypeEnum.logged_in) {
            if (loginState.response.authorization !== null && loginState.response.authorization !== undefined) {
                dispatch(setUser(loginState.response.authorization))
                dispatch(clearLogin())
                navigate('/dashboard')
            }
        }
    }, [loginState.response])
    return (
        <Container>
            <Row className={styles.row}>
                <Col lg={{span: 6, offset: 3}} className={styles.cols}>
                    <Card className={styles.card}>
                        <Card.Body>
                             <div>
                                <div className={styles.title}>
                                    Sign in to WaExplorer
                                </div>
                                <Form onSubmit={onSubmit}
                                      noValidate>
                                    <FormGroup className={styles.group}>
                                        <Form.Label className={styles.label}>Username</Form.Label>
                                        <Form.Control name='username' type='text' isInvalid={validity.username?.valid === false} />
                                        <Form.Control.Feedback type={validity.username ? (validity.username.valid ? 'valid' : 'invalid') : undefined}>{validity.username ? validity.username.message : null}</Form.Control.Feedback>
                                    </FormGroup>
                                    <FormGroup className={styles.group}>
                                        <Form.Label className={styles.label}>Password</Form.Label>
                                        <Form.Control name='password' type='password' isInvalid={validity.password?.valid === false} />
                                        <Form.Control.Feedback type={validity.password ? (validity.password.valid ? 'valid' : 'invalid') : undefined}>{validity.password ? validity.password.message : null}</Form.Control.Feedback>
                                    </FormGroup>
                                    <FormGroup className={styles.group}>
                                        <Form.Check label='Stay signed in' reverse formNoValidate/>
                                    </FormGroup>
                                    <div className='text-danger d-flex flex-column text-center'>
                                        {loginState.response ? (
                                            loginState.response.type === UserLoginResponseTypeEnum.unauthenticated ?
                                                <div className={styles.errorBlock}>
                                                    <span>Not authenticated:</span>
                                                    <span>{loginState.response.message}</span>
                                                </div> :
                                            loginState.response.type === UserLoginResponseTypeEnum.challenge_required ?
                                                <div className={styles.errorBlock}>
                                                    <span>Challenge required:</span>
                                                    <span>{loginState.response.message}</span>
                                                    <span>Sorry, client does not support this feature yet</span>
                                                </div> :
                                            loginState.response.type === UserLoginResponseTypeEnum.bad_request ?
                                                <div className={styles.errorBlock}>
                                                    <span>Bad Request</span>
                                                    <span>{loginState.response.message}</span>
                                                </div> :
                                                loginState.response.type === UserLoginResponseTypeEnum.logged_in ?
                                                    null :
                                                <div className={styles.errorBlock}>
                                                    <span>{loginState.response.message}</span>
                                                </div>
                                        ) : loginState.errorMessage == "Load failed" ? "Could not connect to server" : loginState.errorMessage}
                                    </div>
                                    <FormGroup className={styles.submitGroup}>
                                        {loginState.status === 'idle' ?
                                            <Button type='submit'>Continue</Button>
                                            :
                                            <Button variant="primary" disabled>
                                                <Spinner size="sm" role="status" aria-hidden="true" />
                                            </Button>}
                                    </FormGroup>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}