import {Api} from "./Api";
import {WebSessionQuery} from "../Model/WebSessionQuery";
import {UnifiedWebSession} from "../Model/UnifiedWebSession";
import {Typed} from "../Schema/models/Typed";
import {WaWebSessionDto} from "../Schema/models/WaWebSessionDto";
import {WebSessionDto} from "../Schema/models/WebSessionDto";
import { WebSessionTimelineQuery } from "../Model/WebSessionTimelineQuery";
import { WebSessionTimelineEntryDto } from "../Schema/models/WebSessionTimelineEntryDto";

export class WebSessionsApi extends Api {

    static async unifyTypedWebSessions(typed: Typed[]): Promise<UnifiedWebSession[]> {
        return typed.map((typedWebSession: Typed) => {
            switch (typedWebSession.type) {
                case "WaWebSessionDto": {
                    return { type: typedWebSession.type, model: typedWebSession.model, session: typedWebSession.data as WaWebSessionDto }
                }
                case "WebSessionDto": {
                    return { type: typedWebSession.type, model: typedWebSession.model, session: typedWebSession.data as WebSessionDto }
                }
                default: {
                    throw new Error(`Could not transform web session ${typedWebSession.type}@${typedWebSession.model} to unified web session`)
                }
            }
        })
    }

    async queryUserWebSessions(query: WebSessionQuery): Promise<UnifiedWebSession[]> {
        return this.newCall<Typed[]>(`users/${query.user_id}/${query.model}/web_sessions/query`)
            .fetchJson()
            .then(e => WebSessionsApi.unifyTypedWebSessions(e))
    }

    async queryWebSessionTimeline(query: WebSessionTimelineQuery): Promise<WebSessionTimelineEntryDto[]> {
        return this.newCall<WebSessionTimelineEntryDto[]>(`web_sessions/${query.web_session_id}/${query.model}/timeline/query`)
            .fetchJson();
    }
}