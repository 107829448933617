import {Button, Card, Col, Container, Row} from "react-bootstrap";
import React, {ReactElement} from "react";
import styles from './Dashboard.module.css';
import {ArrowRight} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import {useUser} from "../../Views/AuthGuard/UserContext";
import {canRequestAccess, haveAccess} from "../../Core/Model/AuthorizedUser";
import {UsersDashboard} from "./UsersDashboard/UsersDashboard";
import {PhonesDashboard} from "./PhonesDashboard/PhonesDashboard";
import {ManageDashboard} from "./ManageDashboard/ManageDashboard";

export interface DashboardEntry {
    id: string
    name: string | ReactElement
    description: string | ReactElement,
    element: () => ReactElement
}

interface DashboardAuthEntry extends DashboardEntry {
    haveAccess: boolean | (() => boolean)
    canRequestAccess: boolean | (() => boolean)
}

export const dashboardEntries: DashboardEntry[] = [
    {
        id: 'users',
        name: 'Users',
        description: 'Query users by token or user ids to get full information about its activity in the system',
        element: UsersDashboard
    },
    {
        id: 'phones',
        name: 'Phones',
        description: 'Query phone numbers or usernames to get information about subscriptions using them',
        element: PhonesDashboard
    },
    {
        id: 'manage',
        name: 'Manage',
        description: 'Manage internal users and access scopes',
        element: ManageDashboard
    }
]

const DashboardCard = (props: DashboardAuthEntry) => {
    const navigate = useNavigate()
    const haveAccess = typeof props.haveAccess === 'function' ? props.haveAccess() : props.haveAccess;
    const canRequestAccess = typeof props.canRequestAccess === 'function' ? props.canRequestAccess() : props.canRequestAccess;
    return (
        <Card className={haveAccess ? styles.card : styles.cardDisabled}>
            <Card.Body>
                <Card.Title onClick={() => haveAccess && navigate(`/dashboard/${props.id}`)}>
                    <div className={styles.cardTitle}>
                        <span>{props.name} dashboard</span>
                        <ArrowRight />
                    </div>
                </Card.Title>
                <Card.Text>
                    <span>{props.description}</span>
                </Card.Text>
            </Card.Body>
            {!haveAccess &&
                <Card.Footer className='text-danger'>
                    <div className={styles.noAccess}>
                        <div>You don't have access!</div>
                        {canRequestAccess && <Button variant='outline-danger' size='sm'>Request permissions</Button>}
                    </div>
                </Card.Footer>}
        </Card>
    )
}

export const Dashboard = () => {
    const user = useUser()
    return (
        <Container>
            <Row>
                <Col>
                    <Row>
                       <div className={styles.cardsList}>
                           {dashboardEntries.map(entry =>
                               <DashboardCard
                                   key={entry.id}
                                   haveAccess={() => haveAccess(user, entry.id)}
                                   canRequestAccess={() => canRequestAccess(user, entry.id)}
                                   {...entry} />
                           )}
                       </div>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}