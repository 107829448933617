import {Api} from "./Api";
import {PhoneSubscriptionQuery, SubscriptionQuery} from "../Model/SubscriptionQuery";
import {UnifiedSubscription, unifiedSubscriptionActive} from "../Model/UnifiedSubscription";
import {Typed} from "../Schema/models/Typed";
import {WaUserSubscriptionDto} from "../Schema/models/WaUserSubscriptionDto";
import {SubscriptionPhoneDto} from "../Schema/models/SubscriptionPhoneDto";

export class SubscriptionsApi extends Api {

    static async unifyTypedSubscriptions(typed: Typed[]): Promise<UnifiedSubscription[]> {
        return typed.map((typedSubscription: Typed) => {
            switch (typedSubscription.type) {
                case "WaUserSubscriptionDto": {
                    return { type: typedSubscription.type, model: typedSubscription.model, subscription: typedSubscription.data as WaUserSubscriptionDto }
                }
                case "SubscriptionPhoneDto": {
                    return { type: typedSubscription.type, model: typedSubscription.model, subscription: typedSubscription.data as SubscriptionPhoneDto }
                }
                default: {
                    throw new Error(`Could not transform subscription ${typedSubscription.type}@${typedSubscription.model} to unified subscription`)
                }
            }
        })
    }

    async queryUserSubscriptions(query: SubscriptionQuery): Promise<UnifiedSubscription[]> {
        return this.newCall<Typed[]>(`users/${query.user_id}/${query.model}/subscriptions/query`)
            .fetchJson()
            .then(e => SubscriptionsApi.unifyTypedSubscriptions(e))
            .then(e => e.sort((a, b) => unifiedSubscriptionActive(a) ? -1 : 1))
    }

    async queryPhoneSubscriptions(query: PhoneSubscriptionQuery): Promise<UnifiedSubscription[]> {
        return this.newCall<Typed[]>(`phones/query`)
            .withQueries({ model: query.model, phone: query.phone })
            .fetchJson()
            .then(e => SubscriptionsApi.unifyTypedSubscriptions(e))
            .then(e => e.sort((a, b) => unifiedSubscriptionActive(a) ? -1 : 1))
    }
}