import {UnifiedWebSession} from "../../Core/Model/UnifiedWebSession";
import {Badge} from "react-bootstrap";
import styles from './UnifiedWebSessionRowView.module.css'

const UnifiedWebSessionRowContent = ({ unifiedWebSession }: { unifiedWebSession: UnifiedWebSession }) => {
    switch (unifiedWebSession.type) {
        case "WaWebSessionDto": {
            const status = unifiedWebSession.session.status.web_session_status_name
            const statusVariant = status === 'active' ? 'secondary' :
                status === 'finished' ? 'success' : 'danger'
            return (
                <div>
                    <div className={styles.header}>
                        <span className={styles.id}>{unifiedWebSession.session.web_session_id}</span>
                        <Badge bg={statusVariant}>{unifiedWebSession.session.status.web_session_status_name}</Badge>
                    </div>
                    <div>
                        {unifiedWebSession.session.create_date.toString()}
                    </div>
                </div>
            )
        }
        case "WebSessionDto": {
            const status = unifiedWebSession.session.status.name
            const statusVariant = status === 'ACTIVE' ? 'secondary' :
                status === 'FINISHED' ? 'success' : 'danger'
            return (
                <div>
                    <div className={styles.header}>
                        <span className={styles.id}>{unifiedWebSession.session.web_session_id}</span>
                        <Badge bg={statusVariant}>{unifiedWebSession.session.status.name}</Badge>
                    </div>
                    <div>
                        {unifiedWebSession.session.create_date.toString()}
                    </div>
                </div>
            )
        }
        default: {
            return (
                <div>Unknown web session type</div>
            )
        }
    }
}

export const UnifiedWebSessionRowView = ({ unifiedWebSession }: { unifiedWebSession: UnifiedWebSession }) => {
    return (
        <>
            <UnifiedWebSessionRowContent unifiedWebSession={unifiedWebSession} />
        </>
    )
}