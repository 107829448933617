import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AuthApi} from "../Api/AuthApi";
import {RootState} from "./store";
import {apiProvider} from "./apiProvider";
import {UserRefreshRequest} from "../Schema/models/UserRefreshRequest";
import {UserRefreshResponse} from "../Schema/models/UserRefreshResponse";

interface RefreshState {
    response: UserRefreshResponse | null
    status: 'idle' | 'loading'
    errorMessage: string | null
}

const initialState: RefreshState = {
    status: 'idle',
    response: null,
    errorMessage: null
}

export const refreshLoginAsync = createAsyncThunk(
    'refresh/refreshLoginAsync',
    apiProvider(AuthApi, (request: UserRefreshRequest, api: AuthApi) => {
        return api.userRefresh(request)
    }, { handleUnauthorized: false })
)

const refreshSlice = createSlice({
    name: 'refresh',
    initialState,
    reducers: {
        clearRefresh: (state) => {
            state.response = null
        }
    },
    extraReducers: builder => {
        builder.addCase(refreshLoginAsync.pending, (state) => {
            state.status = 'loading'
            state.response = null
            state.errorMessage = null
        }).addCase(refreshLoginAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.response = action.payload
            state.errorMessage = null
        }).addCase(refreshLoginAsync.rejected, (state, action) => {
            state.status = 'idle'
            state.response = null
            state.errorMessage = action.error.message ?? "Error occurred while performing login"
        })
    }
})

export default refreshSlice.reducer;
export const { clearRefresh } = refreshSlice.actions;
export const selectRefreshState = (state: RootState) => state.refresh;