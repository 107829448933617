import {UnifiedUser} from "../../Core/Model/UnifiedUser";
import {AppUserDto} from "../../Core/Schema/models/AppUserDto";
import {Badge, ListGroupItem} from "react-bootstrap";
import * as React from "react";
import {useAppSelector} from "../../Core/Store/hooks";
import {selectModelsState} from "../../Core/Store/modelsSlice";

import styles from './UnifiedUserRowView.module.css'

export const UnifiedUserRowViewContent = ({ unifiedUser }: { unifiedUser: UnifiedUser }) => {
    switch (unifiedUser.type) {
        case "AppUserDto": {
            return (
                <div>
                    <div className={styles.userTitle}>
                        <div className={styles.userId}>{unifiedUser.user.id}</div>
                    </div>
                    <div>
                        <span>{unifiedUser.user.auth_token}</span>
                    </div>
                </div>
            )
        }
        case "WaUserDto": {
            const isActive = unifiedUser.user.user_status.user_status_name === 'Active'
            return (
                <div>
                    <div className={styles.userTitle}>
                        <div className={styles.userId}>{unifiedUser.user.user_id}</div>
                        <Badge pill bg={isActive ? 'success' : 'danger'}>{unifiedUser.user.user_status.user_status_name}</Badge>
                    </div>
                    <div>
                        <span>{unifiedUser.user.login_token}</span>
                    </div>
                </div>
            )
        }
        default: {
            return (
                <div>Unknown user type</div>
            )
        }
    }
}

export const UnifiedUserRowView = ({ unifiedUser }: { unifiedUser: UnifiedUser }) => {
    const models = useAppSelector(selectModelsState)
    const model = models.models?.find(e => e.name === unifiedUser.model)
    return (
        <div>
            <div>
                <UnifiedUserRowViewContent unifiedUser={unifiedUser} />
            </div>
            <div>
                <Badge pill bg={model?.is_production ? 'danger' : 'primary'}>{model?.display_name ?? unifiedUser.model}</Badge>
            </div>
        </div>
    )
}