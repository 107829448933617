import {createAsyncThunk, createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {ContractQuery} from "../Model/ContractQuery";
import {UnifiedContract, unifiedContractKey} from "../Model/UnifiedContract";
import {ContractsApi} from "../Api/ContractsApi";
import {apiProvider} from "./apiProvider";
import {shallowEqual} from "react-redux";
import {UnifiedUser, unifiedUserKey} from "../Model/UnifiedUser";

export interface UserContractsState {
    query: ContractQuery | null,
    contracts: UnifiedContract[] | null
    selected: UnifiedContract | null,
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
    refreshed: UnifiedContract | null
    currentQuery: ContractQuery | null
}
export const initialState: UserContractsState = {
    query: null,
    currentQuery: null,
    contracts: null,
    selected: null,
    loadMessage: null,
    status: 'idle',
    refreshed: null
}

export const loadContractsAsync = createAsyncThunk(
    'userContracts/loadContractsAsync',
    apiProvider(ContractsApi, (request: ContractQuery, api: ContractsApi) => {
        return api.queryUserContracts(request)
    })
)

export const userContractsSlice = createSlice({
    name: 'userContracts',
    initialState,
    reducers: {
        selectContract: (state, action: PayloadAction<UnifiedContract | null>) => {
            state.selected = action.payload
            state.refreshed = null
        },
        changeUserContractQuery: (state, action: PayloadAction<ContractQuery | null>) => {
            state.query = action.payload
            state.contracts = null
            state.refreshed = null
        },
        refreshUserContracts: (state) => {
            if (state.query !== null) {
                state.query = {...state.query}
                state.contracts = null
                state.refreshed = state.selected
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadContractsAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadContractsAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.loadMessage = null
            state.contracts = action.payload
            const s = current(state)
            if (s.refreshed !== null && !shallowEqual(s.currentQuery, s.query)) {
                state.selected = null
            } else if (s.refreshed !== null) {
                state.selected = action.payload.find(u => unifiedContractKey(u) === unifiedContractKey(s.refreshed as UnifiedContract)) ?? null
            }
            state.refreshed = null
            state.currentQuery = state.query
        }).addCase(loadContractsAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.contracts = null
            state.loadMessage = action.error.message ?? 'Error occurred loading contracts'
            state.selected = null
            state.refreshed = null
        })
    }
})

export default userContractsSlice.reducer;
export const { selectContract, changeUserContractQuery, refreshUserContracts } = userContractsSlice.actions;
export const selectUserContractsState = (state: RootState) => state.userContracts;
