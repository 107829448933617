import {createAsyncThunk, createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {WebSessionQuery} from "../Model/WebSessionQuery";
import {UnifiedWebSession, unifiedWebSessionKey} from "../Model/UnifiedWebSession";
import {WebSessionsApi} from "../Api/WebSessionsApi";
import {apiProvider} from "./apiProvider";
import {shallowEqual} from "react-redux";
import {UnifiedUser, unifiedUserKey} from "../Model/UnifiedUser";

export interface UserWebSessionsState {
    query: WebSessionQuery | null,
    sessions: UnifiedWebSession[] | null
    selected: UnifiedWebSession | null,
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
    refreshed: UnifiedWebSession | null
    currentQuery: WebSessionQuery | null
}
export const initialState: UserWebSessionsState = {
    query: null,
    sessions: null,
    selected: null,
    loadMessage: null,
    status: 'idle',
    currentQuery: null,
    refreshed: null
}

export const loadWebSessionsAsync = createAsyncThunk(
    'userWebSessions/loadWebSessionsAsync',
    apiProvider(WebSessionsApi, (query: WebSessionQuery, webSessionsApi: WebSessionsApi) => {
        return webSessionsApi.queryUserWebSessions(query)
    })
)

export const userWebSessionsSlice = createSlice({
    name: 'userWebSessions',
    initialState,
    reducers: {
        selectWebSession: (state, action: PayloadAction<UnifiedWebSession | null>) => {
            state.selected = action.payload
            state.refreshed = null
        },
        changeWebSessionQuery: (state, action: PayloadAction<WebSessionQuery | null>) => {
            state.query = action.payload
            state.sessions = null
            state.refreshed = null
        },
        refreshWebSessions: (state) => {
            if (state.query) {
                state.query = {...state.query}
                state.sessions = null
                state.refreshed = state.selected
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadWebSessionsAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadWebSessionsAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.loadMessage = null
            state.sessions = action.payload
            const s = current(state)
            if (s.refreshed !== null && !shallowEqual(s.currentQuery, s.query)) {
                state.selected = null
            } else if (s.refreshed !== null) {
                state.selected = action.payload.find(u => unifiedWebSessionKey(u) === unifiedWebSessionKey(s.refreshed as UnifiedWebSession)) ?? null
            }
            state.refreshed = null
            state.currentQuery = state.query
        }).addCase(loadWebSessionsAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.sessions = null
            state.loadMessage = action.error.message ?? 'Error occurred loading contracts'
            state.selected = null
            state.refreshed = null
        })
    }
})

export default userWebSessionsSlice.reducer;
export const { selectWebSession, changeWebSessionQuery, refreshWebSessions } = userWebSessionsSlice.actions;
export const selectUserWebSessionsState = (state: RootState) => state.userWebSessions;
