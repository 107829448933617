import {ApiException} from "../Api/Api";
import {RootState} from "./store";
import {UnauthorizedResponse} from "../Schema/models/UnauthorizedResponse";
import {setChallenge, setLogout} from "./authSlice";

export interface ApiProviderParams {
    handleUnauthorized: boolean
}

export const apiProvider =
    <A, Req, Resp>(
        ctor: { new(accessToken: string | null): A ;},
        block: (request: Req, api: A, state: RootState) => Promise<Resp>,
        params: ApiProviderParams = { handleUnauthorized: true }
    ): (request: Req, options: any) => Promise<Resp> => {
        return async (request: Req, options: any): Promise<Resp> => {
            const state = options.getState() as RootState
            const dispatch = options.dispatch
            const api = new ctor(state.auth.user?.token ?? null)
            try {
                return await block(request, api, state)
            } catch (e) {
                console.warn(`catch ${e}`)
                if (e instanceof ApiException) {
                    console.warn(`catch api error ${e.message}`)
                    if (params.handleUnauthorized && e.response !== null && e.response.status === 401) {
                        console.warn(`handle api 401 ${e.name} ${ctor}`)
                        const body = (await e.response.json()) as UnauthorizedResponse
                        if (body.type === 'forbidden') {
                            dispatch(setLogout(true))
                        } else if (body.type === 'expired' && body.challenge) {
                            dispatch(setChallenge(body.challenge))
                        } else {
                            dispatch(setLogout(true))
                        }
                        return options.rejectWithValue('refresh_required')
                    }
                }
                throw e
            }
        }
    }