import {configureStore} from "@reduxjs/toolkit";
import modelsSlice from "./modelsSlice";
import usersSlice from "./usersSlice";
import userSubscriptionsSlice from "./userSubscriptionsSlice";
import userContractsSlice from "./userContractsSlice";
import subscriptionActivitySlice from "./subscriptionActivitySlice";
import phoneTcTokensSlice from "./phoneTcTokensSlice";
import userWebSessionsSlice from "./userWebSessionsSlice";
import authSlice from "./authSlice";
import loginSlice from "./loginSlice";
import refreshSlice from "./refreshSlice";
import queryHistorySlice from "./queryHistorySlice";
import manageUsersSlice from "./manageUsersSlice";
import webSessionTimelineSlice from "./webSessionTimelineSlice";
import phonesSlice from "./phonesSlice";
import duplicateDeleteSlice from "./duplicateDeleteSlice";
import manageUserScopesSlice from "./manageUserScopesSlice";


export const store = configureStore({
    reducer: {
        models: modelsSlice,
        users: usersSlice,
        userSubscriptions: userSubscriptionsSlice,
        userContracts: userContractsSlice,
        subscriptionActivity: subscriptionActivitySlice,
        phoneTcTokens: phoneTcTokensSlice,
        userWebSessions: userWebSessionsSlice,
        auth: authSlice,
        login: loginSlice,
        refresh: refreshSlice,
        queryHistory: queryHistorySlice,
        manageUsers: manageUsersSlice,
        webSessionTimeline: webSessionTimelineSlice,
        phones: phonesSlice,
        duplicateDelete: duplicateDeleteSlice,
        manageUserScopes: manageUserScopesSlice
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch