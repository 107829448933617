import {Container, Tab, Tabs} from "react-bootstrap";
import {ManageUsers} from "./ManageUsers/ManageUsers";
import {ManageScopes} from "./ManageScopes/ManageScopes";

export const ManageDashboard = () => {
    return (
        <Container>
            <Tabs defaultActiveKey="users" className="mb-3">
                <Tab eventKey="users" title="Users">
                    <ManageUsers />
                </Tab>
                <Tab eventKey="scopes" title="Access Scopes">
                    <ManageScopes />
                </Tab>
            </Tabs>
        </Container>
    )
}