import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {SubscriptionActivityQuery} from "../Model/SubscriptionActivityQuery";
import {UnifiedActivity} from "../Model/UnifiedActivity";
import {ActivityApi} from "../Api/ActivityApi";
import {apiProvider} from "./apiProvider";

export interface SubscriptionActivityState {
    query: SubscriptionActivityQuery | null,
    activities: UnifiedActivity[] | null
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
}
export const initialState: SubscriptionActivityState = {
    query: null,
    activities: null,
    loadMessage: null,
    status: 'idle'
}

export const loadSubscriptionActivityAsync = createAsyncThunk(
    'subscriptionActivity/loadSubscriptionActivityAsync',
    apiProvider(ActivityApi, (request: SubscriptionActivityQuery, api: ActivityApi) => {
        return api.querySubscriptionActivity(request)
    })
)

export const subscriptionActivitySlice = createSlice({
    name: 'subscriptionActivity',
    initialState,
    reducers: {
        changeSubscriptionActivityQuery: (state, action: PayloadAction<SubscriptionActivityQuery | null>) => {
            state.query = action.payload
            state.activities = null
        },
        refreshSubscriptionActivity: (state) => {
            if (state.query) {
                state.query = {...state.query}
                state.activities = null
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadSubscriptionActivityAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadSubscriptionActivityAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.loadMessage = null
            state.activities = action.payload
        }).addCase(loadSubscriptionActivityAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.activities = null
            state.loadMessage = action.error.message ?? 'Error occurred loading activities'
        })
    }
})

export default subscriptionActivitySlice.reducer;
export const { changeSubscriptionActivityQuery, refreshSubscriptionActivity } = subscriptionActivitySlice.actions;
export const selectSubscriptionActivityState = (state: RootState) => state.subscriptionActivity;
