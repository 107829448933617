import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {apiProvider} from "./apiProvider";
import {ManageApi} from "../Api/ManageApi";
import {UserScopeDto} from "../Schema/models/UserScopeDto";
import {ManageUserScopesQuery} from "../Model/ManageUserScopesQuery";

export interface ManageUsersState {
    userScopes: UserScopeDto[] | null
    selected: UserScopeDto | null,
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required',
    query: ManageUserScopesQuery | null,
    refreshed: UserScopeDto | null
}
export const initialState: ManageUsersState = {
    userScopes: null,
    selected: null,
    loadMessage: null,
    status: 'idle',
    query: null,
    refreshed: null
}

export const loadManageUserScopesAsync = createAsyncThunk(
    'manageUserScopes/loadManageUserScopesAsync',
    apiProvider(ManageApi, (request: ManageUserScopesQuery, api: ManageApi, state) => {
        const refreshed = state.userSubscriptions.refreshed
        return api.queryManageUserScopes(request.user_id).then(userScopes => {
            return {userScopes, refreshed}
        })
    })
)

export const manageUserScopesSlice = createSlice({
    name: 'manageUserScopes',
    initialState,
    reducers: {
        selectManageUserScope: (state, action: PayloadAction<UserScopeDto | null>) => {
            state.selected = action.payload
            state.refreshed = null
        },
        changeManageUserScopesQuery: (state, action: PayloadAction<ManageUserScopesQuery | null>) => {
            state.query = action.payload
            state.userScopes = null
            state.refreshed = null
        },
        refreshManageUserScopes: (state) => {
            if (state.query !== null) {
                state.query = {...state.query}
                state.userScopes = null;
                state.refreshed = state.selected
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadManageUserScopesAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadManageUserScopesAsync.fulfilled, (state, action) => {
            const {userScopes, refreshed} = action.payload
            state.status = 'idle'
            state.loadMessage = null
            state.userScopes = userScopes
            state.refreshed = null
            // state.selected = refreshed !== null ? subscriptions.find(s => unifiedSubscriptionKey(s) === unifiedSubscriptionKey(refreshed)) ?? null : null
        }).addCase(loadManageUserScopesAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.userScopes = null
            state.loadMessage = action.error.message ?? 'Error occurred loading user scopes'
            state.selected = null
            state.refreshed = null
        })
    }
})

export default manageUserScopesSlice.reducer;
export const { selectManageUserScope, changeManageUserScopesQuery, refreshManageUserScopes } = manageUserScopesSlice.actions;
export const selectManageUserScopesState = (state: RootState) => state.manageUserScopes;
