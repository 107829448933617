import {HTTPMethod} from "../Schema";

function requireEnv(key: string): string {
    const value = process.env[key]
    if (value === undefined || value === null || value.length === 0) {
        throw new Error(`process.env.${key} is required`)
    }
    return value;
}

export class ApiException extends Error {

    response: Response

    constructor(response: Response, message: string) {
        super(message);
        this.response = response;
    }
}

export type QueryValue = string | number | string[] | number[] | null | undefined
export type HeaderValue = string | number | null | undefined

export class CallBuilder<T> {

    private readonly api: Api
    private readonly endpoint: string
    private method: HTTPMethod = 'GET'
    private headers: { [key: string]: HeaderValue } = {}
    private query: { [key: string]: QueryValue } = {}
    private omitAuthorization: boolean = false
    private body: string | undefined = undefined

    constructor(
        endpoint: string,
        api: Api,
        initialHeaders: { [key: string]: HeaderValue } = {}
    ) {
        this.endpoint = endpoint
        this.api = api
        this.withHeaders(initialHeaders)
    }

    withQueries(query: object): CallBuilder<T> {
        for (const [k, v] of Object.entries(query)) {
            this.pushQuery(k, v)
        }
        return this
    }

    withQuery(key: string, value: QueryValue): CallBuilder<T> {
        this.pushQuery(key, value)
        return this
    }

    withHeaders(headers: object): CallBuilder<T> {
        for (const [k, v] of Object.entries(headers)) {
            this.pushHeader(k, v)
        }
        return this
    }

    withHeader(key: string, value: HeaderValue): CallBuilder<T> {
        this.pushHeader(key, value)
        return this
    }

    withBody<R>(data: R): CallBuilder<T> {
        this.body = JSON.stringify(data)
        return this.withHeader('Content-Type', 'application/json')
    }

    withBodyString(string: string, contentType: string): CallBuilder<T> {
        this.body = string
        return this.withHeader('Content-Type', contentType)
    }

    withoutAuthorization(): CallBuilder<T> {
        this.omitAuthorization = true
        return this
    }

    withMethod(method: HTTPMethod): CallBuilder<T> {
        this.method = method
        return this
    }

    fetchJson(): Promise<T> {
        const headers: Headers = new Headers({})
        for (const [k, v] of Object.entries(this.headers)) {
            if (v !== undefined && v !== null) {
                headers.set(k, v.toString())
            }
        }
        return this.api.processJson<T>(fetch(this.api.buildUrl(this.endpoint, this.query), {
            method: this.method,
            headers: headers,
            body: this.body
        }))
    }

    fetch(): Promise<void> {
        const headers: Headers = new Headers({})
        for (const [k, v] of Object.entries(this.headers)) {
            if (v !== undefined && v !== null) {
                headers.set(k, v.toString())
            }
        }
        return this.api.processVoid(fetch(this.api.buildUrl(this.endpoint, this.query), {
            method: this.method,
            headers: headers,
            body: this.body
        }))
    }

    private pushQuery(k: string, v: any | undefined | null) {
        if (v === undefined || v === null) {
            this.query[k] = undefined
        }
        if (Array.isArray(v)) {
            const current = this.query[k]
            if (current === undefined || current === null) {
                this.query[k] = v
            } else if (Array.isArray(current)) {
                this.query[k] = [...current, ...v]
            } else {
                this.query[k] = [current, ...v]
            }
        } else {
            this.query[k] = v
        }
    }

    private pushHeader(k: string, v: any | undefined | null) {
        if (v === null || v === undefined) {
            this.headers[k] = undefined;
        }
        this.headers[k] = v.toString()
    }

}

export class Api {

    baseUrl: string
    accessToken: string | null

    constructor(accessToken: string | null) {
        this.baseUrl = requireEnv('REACT_APP_BASE_URL');
        this.accessToken = accessToken
    }

    newCall<T>(endpoint: string): CallBuilder<T> {
        return new CallBuilder<T>(endpoint, this, this.accessToken ? { 'Authorization': this.accessToken } : {})
    }

    buildUrl(endpoint: string, query: object = {}): URL {
        const url = new URL(endpoint, this.baseUrl)
        for (const [key, value] of Object.entries(query)) {
            if (value === null || value === undefined) {
                continue;
            }
            if (Array.isArray(value)) {
                for (const element of value) {
                    url.searchParams.append(key, element)
                }
            } else {
                url.searchParams.append(key, value)
            }
        }
        return url
    }

    async processJson<T>(promise: Promise<Response>): Promise<T> {
        return promise.then(e => this.processJsonResponse<T>(e)).catch(e => this.processError<T>(e))
    }

    async processVoid(promise: Promise<Response>): Promise<void> {
        return promise.then(e => this.processVoidResponse(e)).catch(e => this.processError<void>(e))
    }

    async processError<T>(error: Error): Promise<T> {
        if (error.message.includes('Failed to fetch')) {
            throw Error('Request is failed to perform')
        }
        throw error
    }

    async processResponse<T>(response: Response, bodyFn: (r: Response) => T): Promise<T> {
        switch (response.status) {
            case 200: {
                return bodyFn(response)
            }
            case 404: {
                throw new ApiException(response, "Resource could not be found")
            }
            case 401:
            case 403: {
                throw new ApiException(response, "You can't access this resource")
            }
            case 500:
            case 503: {
                throw new ApiException(response, "Service is temporarily unavailable")
            }
            default: {
                throw new ApiException(response, `Unknown error occurred: ${response.status} ${response.statusText}`)
            }
        }
    }

    async processJsonResponse<T>(response: Response): Promise<T> {
        return this.processResponse(response, async (r) => (await r.json()) as T)
    }

    async processVoidResponse(response: Response): Promise<void> {
        return this.processResponse(response, async () => {})
    }
}