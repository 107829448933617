import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getStorage, setStorage, StorageKey} from "../Storage/storage";
import {RootState} from "./store";

export enum QueryHistoryType {
    USERS = 'users',
    PHONES = 'phones'
}

export interface QueryHistoryEntry {
    value: string
    model: string | null
    type: QueryHistoryType
    date: number
}

function getUsersQueryHistory(): QueryHistoryEntry[] {
    const json = getStorage(StorageKey.HISTORY_USERS)
    if (json === null || json.trim().length === 0) {
        return []
    }
    return JSON.parse(json) as QueryHistoryEntry[]
}

function setUsersQueryHistory(history: QueryHistoryEntry[]) {
    const json = JSON.stringify(history)
    setStorage(StorageKey.HISTORY_USERS, json)
}

function getPhonesQueryHistory(): QueryHistoryEntry[] {
    const json = getStorage(StorageKey.HISTORY_PHONES)
    if (json === null || json.trim().length === 0) {
        return []
    }
    return JSON.parse(json) as QueryHistoryEntry[]
}

function setPhonesQueryHistory(history: QueryHistoryEntry[]) {
    const json = JSON.stringify(history)
    setStorage(StorageKey.HISTORY_PHONES, json)
}


interface QueryHistoryState {
    users: QueryHistoryEntry[]
    phones: QueryHistoryEntry[]
}

const initialState: QueryHistoryState = {
    users: getUsersQueryHistory(),
    phones: getPhonesQueryHistory()
}

const queryHistorySlice = createSlice({
    name: 'queryHistory',
    initialState,
    reducers: {
        pushQueryHistory: (state, action: PayloadAction<QueryHistoryEntry>) => {
            switch (action.payload.type) {
                case QueryHistoryType.USERS: {
                    const last = state.users.length > 0 ? state.users[0] : null
                    if (last === null || last.value !== action.payload.value || last.model !== action.payload.model) {
                        state.users = [action.payload, ...state.users]
                    }
                    break;
                }
                case QueryHistoryType.PHONES: {
                    const last = state.phones.length > 0 ? state.phones[0] : null
                    if (last === null || last.value !== action.payload.value || last.model !== action.payload.model) {
                        state.phones = [action.payload, ...state.phones]
                    }
                    break;
                }
            }
            if (state.users.length > 25) {
                state.users = state.users.slice(0, 25)
            }
            if (state.phones.length > 25) {
                state.phones = state.phones.slice(0, 25)
            }
            setUsersQueryHistory(state.users)
            setPhonesQueryHistory(state.phones)
        }
    }
})

export default queryHistorySlice.reducer;
export const { pushQueryHistory } = queryHistorySlice.actions;
export const selectQueryHistoryState = (state: RootState) => state.queryHistory;