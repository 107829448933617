import {
    UnifiedTcToken, unifiedTcTokenKey,
    unifiedTcTokenTypeDescription, unifiedTcTokenTypeName,
    unifiedTcTokenTypeVariant
} from "../../Core/Model/UnifiedTcToken";
import {Badge, Button} from "react-bootstrap";
import styles from './UnifiedTcTokenRowView.module.css'
import {Copyable} from "../Copyable";
import {ArrowRight, ArrowRightCircleFill, ArrowRightSquareFill, Asterisk} from "react-bootstrap-icons";
import {SimpleTooltip} from "../SimpleTooltip";
import {setDuplicateToDelete} from "../../Core/Store/duplicateDeleteSlice";
import {unifiedSubscriptionKey} from "../../Core/Model/UnifiedSubscription";
import {useAppDispatch, useAppSelector} from "../../Core/Store/hooks";
import {haveAccess} from "../../Core/Model/AuthorizedUser";
import {selectAuthState} from "../../Core/Store/authSlice";

const UnifiedTcTokenRowContent = ({ unifiedTcToken, tokenInUse, showDelete }: { unifiedTcToken: UnifiedTcToken, tokenInUse?: number, showDelete?: boolean }) => {
    const dispatch = useAppDispatch()
    const authState = useAppSelector(selectAuthState)
    const showDeleteButton = showDelete === undefined || showDelete;
    const canDelete = authState.user !== null && haveAccess(authState.user, 'delete');
    const onTcTokenDelete = () => {
        if (unifiedTcToken.tcToken.delete_key === undefined) {
            return;
        }
        if (!canDelete) {
            return;
        }
        dispatch(setDuplicateToDelete({
            info: { name: 'TcToken', tcToken: unifiedTcToken },
            model: unifiedTcToken.model,
            id: unifiedTcTokenKey(unifiedTcToken)
        }))
    }
    switch (unifiedTcToken.type) {
        case "TcTokenDto": {
            const type = unifiedTcToken.tcToken.tc_token_type
            const typeVariant = unifiedTcTokenTypeVariant(type.tc_token_type_name)
            const typeDescription = unifiedTcTokenTypeDescription(type.tc_token_type_name)
            const typeName = unifiedTcTokenTypeName(type.tc_token_type_name)
            return (
                <>
                    <div className={styles.header}>
                        {tokenInUse === unifiedTcToken.tcToken.tc_token_id &&
                            <SimpleTooltip tooltip={'This token is currently being used to track this subscription'}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}><Asterisk color='green'/></div>
                            </SimpleTooltip>}
                        <div className={styles.id}>{unifiedTcToken.tcToken.tc_token_id}</div>
                        <div><Badge
                            bg={unifiedTcToken.tcToken.tc_token_status.name == 'disabled' ? 'danger' : 'primary'}>{unifiedTcToken.tcToken.tc_token_status.name}</Badge>
                        </div>
                    </div>
                    <div className={styles.header}>
                        <div><SimpleTooltip tooltip={typeDescription}><Badge
                            bg={typeVariant}>{typeName}</Badge></SimpleTooltip></div>
                        <div><Badge
                            bg={unifiedTcToken.original.tc_token_id === unifiedTcToken.tcToken.tc_token_id ? 'success' : 'secondary'}>{unifiedTcToken.original.age}</Badge>
                        </div>
                    </div>
                    <div className={styles.phone}>{unifiedTcToken.tcToken.phone_num}</div>
                    <Copyable value={unifiedTcToken.tcToken.token}>
                        <code className={styles.token}>{unifiedTcToken.tcToken.token}</code>
                    </Copyable>
                    <div className={styles.footer}>
                        <div>Created {unifiedTcToken.tcToken.create_date.toString()}</div>
                        <div>Received {unifiedTcToken.tcToken.receive_date?.toString() ?? 'N/A'}</div>
                        {unifiedTcToken.tcToken.delete_key !== null && showDeleteButton && <Button disabled={!canDelete} variant='outline-danger' size='sm' onClick={onTcTokenDelete}>Disable this token</Button>}
                    </div>
                </>
            )
        }
        case "WaTcTokenDto": {
            const type = unifiedTcToken.tcToken.tc_token_type
            const typeVariant = unifiedTcTokenTypeVariant(type.tc_token_type_name)
            const typeDescription = unifiedTcTokenTypeDescription(type.tc_token_type_name)
            const typeName = unifiedTcTokenTypeName(type.tc_token_type_name)
            return (
                <>
                    <div className={styles.header}>
                        {tokenInUse === unifiedTcToken.tcToken.tc_token_id &&
                            <SimpleTooltip tooltip={'This token is currently being used to track this subscription'}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}><Asterisk color='green'/></div>
                            </SimpleTooltip>}
                        <div className={styles.id}>{unifiedTcToken.tcToken.tc_token_id}</div>
                        <div><Badge
                            bg={unifiedTcToken.tcToken.status.tc_token_status_name == 'disabled' ? 'danger' : 'primary'}>{unifiedTcToken.tcToken.status.tc_token_status_name}</Badge>
                        </div>
                    </div>
                    <div className={styles.header}>
                        <div><SimpleTooltip tooltip={typeDescription}><Badge
                            bg={typeVariant}>{typeName}</Badge></SimpleTooltip></div>
                        <div><Badge bg={unifiedTcToken.original.tc_token_id === unifiedTcToken.tcToken.tc_token_id ? 'success' : 'secondary'}>{unifiedTcToken.original.age}</Badge></div>
                    </div>
                    <div className={styles.phone}>{unifiedTcToken.tcToken.phone_num}</div>
                    <Copyable value={unifiedTcToken.tcToken.token_value}>
                        <code className={styles.token}>{unifiedTcToken.tcToken.token_value}</code>
                    </Copyable>
                    <div className={styles.footer}>
                        <div>Created {unifiedTcToken.tcToken.create_date.toString()}</div>
                        <div>Received {unifiedTcToken.tcToken.receive_date?.toString() ?? 'N/A'}</div>0
                        {unifiedTcToken.tcToken.delete_key !== null && showDeleteButton && <Button disabled={!canDelete} variant='outline-danger' size='sm' onClick={onTcTokenDelete}>Disable this token</Button>}
                    </div>
                </>
            )
        }
    }
}

export const UnifiedTcTokenRowView = ({ unifiedTcToken, tokenInUse, showDelete }: { unifiedTcToken: UnifiedTcToken, tokenInUse?: number, showDelete?: boolean }) => {
    return (
        <div>
            <UnifiedTcTokenRowContent tokenInUse={tokenInUse} unifiedTcToken={unifiedTcToken} showDelete={showDelete} />
        </div>
    )
}