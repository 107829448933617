import {WaWebSessionDto} from "../Schema/models/WaWebSessionDto";
import {WebSessionDto} from "../Schema/models/WebSessionDto";

export type UnifiedWebSession = {
    type: 'WaWebSessionDto',
    model: string,
    session: WaWebSessionDto
} | {
    type: 'WebSessionDto',
    model: string,
    session: WebSessionDto
}

export function unifiedWebSessionKey(unifiedWebSession: UnifiedWebSession): any {
    switch (unifiedWebSession.type) {
        case "WaWebSessionDto": return unifiedWebSession.session.web_session_id
        case "WebSessionDto": return unifiedWebSession.session.web_session_id
        default: return "never"
    }
}