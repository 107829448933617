import {BrowserRouter, Link, Navigate, Route, Routes, useLocation, useNavigate, useRoutes} from "react-router-dom";
import {Dashboard, dashboardEntries} from "./Dashboard/Dashboard";
import {Button, Container, FormControl, FormText, Modal, Nav, Navbar, NavDropdown, Spinner} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../Core/Store/hooks";
import {selectAuthState, setLogout, setUser} from "../Core/Store/authSlice";
import {FieldValidity, Login} from "./Login/Login";
import React, {useEffect, useState} from "react";
import {clearRefresh, refreshLoginAsync, selectRefreshState} from "../Core/Store/refreshSlice";
import {AuthGuard} from "../Views/AuthGuard/AuthGuard";
import {UserRefreshResponseTypeEnum} from "../Core/Schema/models/UserRefreshResponse";
import styles from "./Login/Login.module.css";
import {haveAccess} from "../Core/Model/AuthorizedUser";
import {DuplicateDeleteDialog, DuplicateDeleteModal} from "../Views/DuplicateDeleteDialog/DuplicateDeleteDialog";

const Navigation = () => {
    const authState = useAppSelector(selectAuthState)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        if (authState.logout) {
            dispatch(setLogout(false))
            navigate('/auth/login')
        }
    }, [authState.logout])
    const onLogout = () => {
        dispatch(setLogout(true))
    }
    if (location.pathname === '/auth/login') {
        return null;
    }
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="#home">
                    WaExplorer
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
                        {dashboardEntries.map(entry =>
                            <Nav.Link
                                key={entry.id}
                                as={Link}
                                disabled={authState.user ? !haveAccess(authState.user, entry.id) : true}
                                to={`/dashboard/${entry.id}`}>{entry.name}</Nav.Link>
                        )}
                    </Nav>
                    {authState.user && <Nav>
                        <NavDropdown title={authState.user.name}>
                            <NavDropdown.Item onClick={onLogout}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

const PasswordModal = () => {
    const authState = useAppSelector(selectAuthState)
    const isActive = authState.challenge !== null
    const [password, setPassword] = useState<string>('')
    const [validity, setValidity] = useState<FieldValidity>({ valid: true, message: '' })
    const dispatch = useAppDispatch()
    const refreshState = useAppSelector(selectRefreshState)
    useEffect(() => {
        if (isActive) {
            dispatch(clearRefresh())
            setPassword('')
            setValidity({ valid: true, message: '' })
        }
    }, [isActive])
    useEffect(() => {
        if (refreshState.response !== null) {
            if (refreshState.response.type === UserRefreshResponseTypeEnum.password_mismatch) {
                setValidity({ valid: false, message: 'Password is not correct' })
                return
            }
            if (refreshState.response.type === UserRefreshResponseTypeEnum.unauthenticated) {
                dispatch(setLogout(true))
                return
            }
            if (refreshState.response.type === UserRefreshResponseTypeEnum.logged_in && refreshState.response.authorization) {
                dispatch(setUser(refreshState.response.authorization))
                return
            }
            setValidity({ valid: false, message: 'Unknown error occurred' })
        }
    }, [refreshState.response])
    const onSubmit = () => {
        if (password === null || password === undefined || password.length < 3) {
            return setValidity({ valid: false, message: 'Enter password to verify' })
        }
        if (authState.challenge === null) {
            return
        }
        dispatch(refreshLoginAsync({ challenge: authState.challenge, password: password }))
    }
    return (
        <Modal show={isActive}>
            <Modal.Header>
                <Modal.Title>Confirm password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={styles.message}>
                    To access dashboard you need to verify your password
                </div>
                <FormControl value={password} onChange={e => setPassword(e.target.value)}
                             type='password' placeholder='Password...'/>
                <FormControl.Feedback type={validity.valid ? 'valid' : 'invalid'}>{validity.message}</FormControl.Feedback>
                {refreshState?.errorMessage?.toString()}
                <div className='text-danger d-flex flex-column text-center'>
                    {refreshState.response ? (
                        refreshState.response.type === UserRefreshResponseTypeEnum.unauthenticated ?
                            <div className={styles.errorBlock}>
                                <span>Could not authenticate</span>
                            </div> :
                            refreshState.response.type === UserRefreshResponseTypeEnum.password_mismatch ?
                                <div className={styles.errorBlock}>
                                    <span>Password is incorrect</span>
                                </div> : null
                    ) : null}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {refreshState.status === 'idle' ?
                    <Button onClick={onSubmit}>Confirm</Button>
                    :
                    <Button variant="primary" disabled>
                        <Spinner size="sm" role="status" aria-hidden="true" />
                    </Button>}
            </Modal.Footer>
        </Modal>
    )
}

export const App = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Navigation />
            <PasswordModal />
            <DuplicateDeleteModal />
            <div className="mt-2">
                <Routes>
                    <Route id='login' path='/auth/login' element={<Login />} />
                    <Route path='/' element={<Navigate to='/dashboard' />} />
                    <Route element={<AuthGuard />}>
                        <Route path='/dashboard' element={<Dashboard />} />
                        {dashboardEntries.map(entry => <Route
                            key={entry.id}
                            path={`/dashboard/${entry.id}`}
                            element={<entry.element />}/>)}
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}