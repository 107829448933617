import {Button, ListGroup, ListGroupItem} from "react-bootstrap";
import * as React from "react";
import {ReactElement, useState} from "react";
import {Copyable} from "./Copyable";

export interface PropertyAction {
    element: () => ReactElement
}
export interface Property {
    key: string
    value: PropertyValue
    additional?: boolean
    action?: PropertyAction
    hideOnUnset?: boolean
}
export type PropertyValue = string | number | null | undefined
export type Properties = Property[]

export const PropertiesTable = ({ properties }: { properties: Properties  }) => {
    const [showAll, setShowAll] = useState(false)
    const hasAdditional = properties.find(({additional}) => additional)
    return (
        <ListGroup variant='flush'>
            {properties
                .filter(({additional, hideOnUnset, value}) => (showAll || !additional) && (!hideOnUnset || value))
                .map(({key, value, additional, action, hideOnUnset}) => {
                    return (
                        <ListGroupItem key={`${key}`} style={{ paddingLeft: 0, paddingRight: 0 }} variant={additional ? 'info' : undefined}>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{key}</div>
                                <Copyable value={value ? value.toString() : null}>
                                    <>{value}</>
                                </Copyable>
                                {value && action ? action.element() : null}
                            </div>
                        </ListGroupItem>
                    )
                })}
            {hasAdditional && <Button size='sm' variant='outline-primary' onClick={() => setShowAll(!showAll)}>
                {showAll ? 'Show basic' : 'Show all'}
            </Button>}
        </ListGroup>
    )
}