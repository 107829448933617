import {useEffect, useRef} from "react";
import {useAppSelector} from "./Store/hooks";
import {selectAuthState} from "./Store/authSlice";

export const useRequest = (query: () => any | undefined, queryStatus: 'refresh_required' | string, queryDeps: any[]) => {
    const authState = useAppSelector(selectAuthState)
    useEffect(
        () => {
            return query()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...queryDeps, authState.user]
    )
}