import {Alert} from "react-bootstrap";
import styles from "../App/Dashboard/UsersDashboard/UsersDashboard.module.css";
import {ExclamationTriangle} from "react-bootstrap-icons";

export const ErrorView = (props: { status: 'idle' | 'refresh_required' | string, loadMessage: string | null }) => {
    if (props.status !== 'refresh_required' && props.loadMessage) {
        return (
            <Alert variant='danger' style={{ borderRadius: 0, marginBottom: 0 }}>
                <div className={styles.usersError}>
                    <ExclamationTriangle size='2em' />
                    {props.loadMessage}
                </div>
            </Alert>
        )
    }
    return null
}