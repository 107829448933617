import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {WebSessionQuery} from "../Model/WebSessionQuery";
import {UnifiedWebSession} from "../Model/UnifiedWebSession";
import {WebSessionsApi} from "../Api/WebSessionsApi";
import {apiProvider} from "./apiProvider";
import { WebSessionTimelineEntryDto } from "../Schema/models/WebSessionTimelineEntryDto";
import { WebSessionTimelineQuery } from "../Model/WebSessionTimelineQuery";

export interface WebSessionTimelineState {
    query: WebSessionTimelineQuery | null,
    timeline: WebSessionTimelineEntryDto[]
    selected: WebSessionTimelineEntryDto | null,
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
}
export const initialState: WebSessionTimelineState = {
    query: null,
    timeline: [],
    selected: null,
    loadMessage: null,
    status: 'idle'
}

export const loadWebWebSessionTimelineAsync = createAsyncThunk(
    'webSessionTimeline/webWebSessionTimelineAsync',
    apiProvider(WebSessionsApi, (query: WebSessionTimelineQuery, webSessionsApi: WebSessionsApi) => {
        return webSessionsApi.queryWebSessionTimeline(query)
    })
)

export const userWebSessionsSlice = createSlice({
    name: 'webSessionTimeline',
    initialState,
    reducers: {
        selectWebSessionTimelineEntry: (state, action: PayloadAction<WebSessionTimelineEntryDto | null>) => {
            state.selected = action.payload
        },
        changeWebSessionTimelineQuery: (state, action: PayloadAction<WebSessionTimelineQuery | null>) => {
            state.query = action.payload
            state.timeline = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadWebWebSessionTimelineAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadWebWebSessionTimelineAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.loadMessage = null
            state.timeline = action.payload
            state.selected = null
        }).addCase(loadWebWebSessionTimelineAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.timeline = []
            state.loadMessage = action.error.message ?? 'Error occurred loading contracts'
            state.selected = null
        })
    }
})

export default userWebSessionsSlice.reducer;
export const { selectWebSessionTimelineEntry, changeWebSessionTimelineQuery } = userWebSessionsSlice.actions;
export const selectWebSessionTimelineState = (state: RootState) => state.webSessionTimeline;
