import {Api} from "./Api";

export class DeleteDuplicateApi extends Api {

    async deleteDuplicate(model: string, duplicateKey: string): Promise<void> {
        return this.newCall(`duplicates/${model}/delete`)
            .withMethod('POST')
            .withQuery('k', duplicateKey)
            .fetch()
    }
}