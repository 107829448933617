import {createAsyncThunk, createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {UnifiedUser} from "../Model/UnifiedUser";
import {UsersQuery} from "../Model/UsersQuery";
import {UsersApi} from "../Api/UsersApi";
import {apiProvider} from "./apiProvider";
import {shallowEqual} from "react-redux";
import {UserDto} from "../Schema/models/UserDto";
import {ManageApi} from "../Api/ManageApi";

export interface ManageUsersState {
    users: UserDto[] | null
    selected: UserDto | null,
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
}
export const initialState: ManageUsersState = {
    users: null,
    selected: null,
    loadMessage: null,
    status: 'idle'
}

export const loadManageUsersAsync = createAsyncThunk(
    'manageUsers/loadManageUsersAsync',
    apiProvider(ManageApi, (request: undefined, api: ManageApi) => {
        return api.queryManageUsers()
    })
)

export const manageUsersSlice = createSlice({
    name: 'manageUsers',
    initialState,
    reducers: {
        selectManageUser: (state, action: PayloadAction<UserDto | null>) => {
            state.selected = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadManageUsersAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadManageUsersAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.loadMessage = null
            state.users = action.payload
            state.selected = null
        }).addCase(loadManageUsersAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.users = null
            state.loadMessage = action.error.message ?? 'Error occurred loading users'
            state.selected = null
        })
    }
})

export default manageUsersSlice.reducer;
export const { selectManageUser } = manageUsersSlice.actions;
export const selectManageUsersState = (state: RootState) => state.manageUsers;
