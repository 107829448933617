import {UnifiedContract} from "../../Core/Model/UnifiedContract";
import * as React from "react";
import {Properties, PropertiesTable} from "../PropertyTable";

export const getUnifiedContractProperties = (unifiedContract: UnifiedContract): Properties => {
    switch (unifiedContract.type) {
        case "WaUserContractDto": {
            const contract = unifiedContract.contract
            return [
                {key: "ID", value: contract.contract_id},
                {key: "Begin Date", value: contract.begin_date.toString()},
                {key: "End Date", value: contract.end_date.toString()},
                {key: "Update Date", value: contract.actual_date.toString()},
            ]
        }
        case "PaymentDto": {
            const contract = unifiedContract.contract
            return [
                {key: "ID", value: contract.id},
                {key: "Begin Date", value: contract.fulfil_time.toString()},
                {key: "End Date", value: contract.valid_until.toString()},
            ]
        }
    }
}

const UnifiedContractDetailsContent = ({ unifiedContract }: { unifiedContract: UnifiedContract }) => {
    switch (unifiedContract.type) {
        case "WaUserContractDto": {
            return (
                <div></div>
            )
        }
        case "PaymentDto": {
            return (
                <div></div>
            )
        }
        default: {
            return (
                <div>Unknown contract type</div>
            )
        }
    }
}

export const UnifiedContractDetailsView = ({ unifiedContract }: { unifiedContract: UnifiedContract }) => {
    return (
        <>
            <PropertiesTable properties={getUnifiedContractProperties(unifiedContract)} />
            <UnifiedContractDetailsContent unifiedContract={unifiedContract} />
        </>
    )
}