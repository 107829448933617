import {UnifiedSubscription} from "../../Core/Model/UnifiedSubscription";
import * as React from "react";
import styles from './UnifiedSubscriptionRowView.module.css'
import {Badge, Button, Dropdown} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";
import {Bell, BellSlash, Eye, EyeSlash, Linkedin, Telegram, Whatsapp} from "react-bootstrap-icons";

export const UnifiedSubscriptionRowContent = ({ unifiedSubscription, canDelete, onDelete }: { unifiedSubscription: UnifiedSubscription, canDelete: boolean, onDelete: (_: UnifiedSubscription) => void }) => {
    switch (unifiedSubscription.type) {
        case "SubscriptionPhoneDto": {
            const status = unifiedSubscription.subscription.status.name
            const statusVariant: Variant = status === 'CONFIRMED' ? 'success' :
                status === 'PHONE_NOT_FOUND' ? 'warning' : 'danger';
            const trackingVariant: Variant = unifiedSubscription.subscription.is_tracking ?
                'success' : 'danger';
            const visibilityIcon = unifiedSubscription.subscription.online_visibility?.name === 'NORMAL' ?
                <Eye /> :
                unifiedSubscription.subscription.online_visibility?.name === 'NOBODY' ?
                    <EyeSlash /> :
                    null;
            const showDeleteAction = unifiedSubscription.subscription?.duplicate?.key && canDelete
            return (
                <>
                    <div className={styles.title}>
                        <div className={styles.id}>{unifiedSubscription.subscription.id}</div>
                        <Badge bg={statusVariant}>{status}</Badge>
                        {unifiedSubscription.subscription.tracking_status &&
                            <Badge bg={trackingVariant}>{unifiedSubscription.subscription.tracking_status}</Badge>}
                        {unifiedSubscription.subscription.duplicate && <Badge pill>Duplicate</Badge>}
                    </div>
                    <div>
                        <div>{unifiedSubscription.subscription.assigned_name}</div>
                    </div>
                    <div className={styles.phone}>
                        <div>{unifiedSubscription.subscription.phone_num}</div>
                        <div>{unifiedSubscription.subscription.notify ? <Bell /> : <BellSlash />}</div>
                        {visibilityIcon && <div>{visibilityIcon}</div>}
                    </div>
                    {showDeleteAction && <Dropdown>
                        <Dropdown.Toggle size='sm' as='a'>
                            Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {showDeleteAction && <Dropdown.Item className='text-danger' onClick={() => onDelete(unifiedSubscription)}>
                                Delete duplicate
                            </Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>}
                </>
            )
        }
        case "WaUserSubscriptionDto": {
            const status = unifiedSubscription.subscription.status.subscription_status_name
            const statusVariant: Variant = status === 'Active' || status === 'Confirmed' ? 'success' :
                status === 'PhoneNotFound' ? 'warning' : 'danger';
            const trackingVariant: Variant = unifiedSubscription.subscription.is_tracking ?
                'success' : 'danger';
            const typeIcon = unifiedSubscription.subscription.type.subscription_type_name === 'telegram' ?
                <Telegram /> :
                unifiedSubscription.subscription.type.subscription_type_name === 'whatsapp' ?
                <Whatsapp /> :
                unifiedSubscription.subscription.type.subscription_type_name === 'linkedin' ?
                <Linkedin /> :
                null;
            const visibilityIcon = unifiedSubscription.subscription.online_visibility?.online_visibility_status_name === 'normal' ?
                <Eye /> :
                unifiedSubscription.subscription.online_visibility?.online_visibility_status_name === 'nobody' ?
                <EyeSlash /> :
                null;

            return (
                <>
                    <div className={styles.title}>
                        <div className={styles.id}>{unifiedSubscription.subscription.subscription_id}</div>
                        <Badge bg={statusVariant}>{status}</Badge>
                        {unifiedSubscription.subscription.tracking_status &&
                            <Badge bg={trackingVariant}>{unifiedSubscription.subscription.tracking_status}</Badge>}
                    </div>
                    <div>
                        <div>{unifiedSubscription.subscription.subscription_name}</div>
                    </div>
                    <div className={styles.phone}>
                        <div>{unifiedSubscription.subscription.phone_num}</div>
                        <div>{unifiedSubscription.subscription.online_notify ? <Bell /> : <BellSlash />}</div>
                        {visibilityIcon && <div>{visibilityIcon}</div>}
                        {typeIcon && <div>{typeIcon}</div>}
                    </div>
                    {typeIcon === null && <div>
                        <Badge pill>{unifiedSubscription.subscription.type.subscription_type_name}</Badge>
                    </div>}
                    {unifiedSubscription.subscription.duplicate && <div>
                        <Badge pill>Duplicate</Badge>
                    </div>}
                </>
            )
        }
        default: {
            return (
                <>Unknown subscription type</>
            )
        }
    }
}

export const UnifiedSubscriptionRowView = ({ unifiedSubscription, canDelete, onDelete }: { unifiedSubscription: UnifiedSubscription, canDelete: boolean, onDelete: (_: UnifiedSubscription) => void }) => {
    return (
        <div>
            <UnifiedSubscriptionRowContent canDelete={canDelete} onDelete={onDelete} unifiedSubscription={unifiedSubscription} />
        </div>
    )
}