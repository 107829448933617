import {Api} from "./Api";
import {SubscriptionActivityQuery} from "../Model/SubscriptionActivityQuery";
import {UnifiedActivity} from "../Model/UnifiedActivity";
import {Typed} from "../Schema/models/Typed";
import {WaActivityRecordDto} from "../Schema/models/WaActivityRecordDto";
import {VisitNoteDto} from "../Schema/models/VisitNoteDto";

export class ActivityApi extends Api {

    static async unifyTypedActivity(typed: Typed[]): Promise<UnifiedActivity[]> {
        return typed.map((typedActivity: Typed) => {
            switch (typedActivity.type) {
                case "WaActivityRecordDto": {
                    return { type: typedActivity.type, model: typedActivity.model, activity: typedActivity.data as WaActivityRecordDto }
                }
                case "VisitNoteDto": {
                    return { type: typedActivity.type, model: typedActivity.model, activity: typedActivity.data as VisitNoteDto }
                }
                default: {
                    throw new Error(`Could not transform activity ${typedActivity.type}@${typedActivity.model} to unified activity`)
                }
            }
        })
    }

    async querySubscriptionActivity(query: SubscriptionActivityQuery): Promise<UnifiedActivity[]> {
        return this.newCall<Typed[]>(`subscriptions/${query.subscription_id}/${query.model}/activity/query`)
            .fetchJson()
            .then(e => ActivityApi.unifyTypedActivity(e))
    }
}