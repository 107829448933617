import {Api} from "./Api";
import {ContractQuery} from "../Model/ContractQuery";
import {UnifiedContract} from "../Model/UnifiedContract";
import {Typed} from "../Schema/models/Typed";
import {PaymentDto} from "../Schema/models/PaymentDto";
import {WaUserContractDto} from "../Schema/models/WaUserContractDto";

export class ContractsApi extends Api {
    static async unifyTypedContracts(typedContracts: Typed[]): Promise<UnifiedContract[]> {
        return typedContracts.map((typedContract: Typed) => {
            switch (typedContract.type) {
                case "PaymentDto": {
                    return { type: typedContract.type, model: typedContract.model, contract: typedContract.data as PaymentDto }
                }
                case "WaUserContractDto": {
                    return { type: typedContract.type, model: typedContract.model, contract: typedContract.data as WaUserContractDto }
                }
                default: {
                    throw new Error(`Could not transform contract ${typedContract.type}@${typedContract.model} to unified contract`)
                }
            }
        })
    }

    async queryUserContracts(query: ContractQuery): Promise<UnifiedContract[]> {
        return this.newCall<Typed[]>(`users/${query.user_id}/${query.model}/contracts/query`)
            .fetchJson()
            .then(e => ContractsApi.unifyTypedContracts(e))
    }
}