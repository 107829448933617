import {UserScopeDto} from "../../Core/Schema/models/UserScopeDto";
import {XCircle} from "react-bootstrap-icons";
import styles from './ManegeUserScopeRow.module.css'
export const ManageUserScopeRow = ({userScope}: {userScope: UserScopeDto}) => {
    const onDisable = () => {

    }
    return (
        <div className={styles.scope_row}>
            <div>{userScope.scope.name}</div>
            <div className={styles.scope_delete} onClick={onDisable}>
                <XCircle color='red' size='1.2em'/>
            </div>
        </div>
    )
}