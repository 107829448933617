import {UserDto} from "../../Core/Schema/models/UserDto";
import {Card, Col, ListGroup, ListGroupItem, Row, Spinner} from "react-bootstrap";
import styles from "../UserDetails/UnifiedUserDetailsView.module.css";
import {ChevronDown} from "react-bootstrap-icons";
import {ErrorView} from "../ErrorView";
import * as React from "react";
import {useAppDispatch, useAppSelector} from "../../Core/Store/hooks";
import {
    changeManageUserScopesQuery, loadManageUserScopesAsync,
    selectManageUserScope,
    selectManageUserScopesState
} from "../../Core/Store/manageUserScopesSlice";
import {ActionCardCloseType, ActionCardTitle} from "../ActionCardTitle/ActionCardTitle";
import {useEffect, useState} from "react";
import {useRequest} from "../../Core/useRequest";
import {UserScopeDto} from "../../Core/Schema/models/UserScopeDto";
import {ManageUserScopeRow} from "../ManageUserScopeRow/ManageUserScopeRow";
import {PropertiesTable} from "../PropertyTable";

const ManageUserScopes = ({user}: {user: UserDto}) => {
    const [isCollapsed, setCollapsed] = useState(false)
    const dispatch = useAppDispatch()
    const manageUserScopesState = useAppSelector(selectManageUserScopesState)
    useEffect(() => {
        if (manageUserScopesState.query?.user_id !== user.user_id) {
            dispatch(changeManageUserScopesQuery({ user_id: user.user_id }))
        }
    }, [])
    useRequest(() => {
        if (manageUserScopesState.status === 'refresh_required' || (manageUserScopesState.status === 'idle' && manageUserScopesState.userScopes === null)) {
            if (manageUserScopesState.query !== null) {
                dispatch(loadManageUserScopesAsync(manageUserScopesState.query))
            }
        }
    }, manageUserScopesState.status, [manageUserScopesState.query])
    const actualScopes = manageUserScopesState.query?.user_id === user.user_id ?
        manageUserScopesState.userScopes : []
    const onScopeSelected = (eventKey: string | null) => {
        if (eventKey !== null && actualScopes !== null) {
            const scope = actualScopes.find(e => e.user_scope_id.toString() === eventKey)
            if (scope !== undefined) {
                dispatch(selectManageUserScope(scope))
            }
        }
    }
    return (
        <Card>
            <Card.Header>
                <ActionCardTitle
                    className={styles.alertTitle}
                    loading={manageUserScopesState.status === 'loading'}
                    closeType={ActionCardCloseType.COLLAPSE}
                    canClose={actualScopes !== null && actualScopes.length > 0}
                    onClose={() => setCollapsed(!isCollapsed)}
                >
                    Scopes
                </ActionCardTitle>
            </Card.Header>
            <ErrorView {...manageUserScopesState} />
            {!isCollapsed && <ListGroup activeKey={manageUserScopesState.selected?.user_scope_id?.toString()} onSelect={onScopeSelected}>
                {actualScopes && actualScopes.map(
                    (userScope: UserScopeDto) => {
                        return (
                            <ListGroupItem style={{ borderRadius: 0 }} action key={userScope.user_scope_id} eventKey={userScope.user_scope_id}>
                                <ManageUserScopeRow userScope={userScope} />
                            </ListGroupItem>
                        )
                    }
                )}
            </ListGroup>}
            {actualScopes && <Card.Footer>{actualScopes.length} scope(s)</Card.Footer>}
        </Card>
    )
}

export const ManageUserDetails = ({user}: {user: UserDto}) => {
    return (
        <div>
            <PropertiesTable properties={[
                { key: 'Created at', value: user.created.toString() },
                { key: 'Deleted at', value: user.disable_date?.toString(), hideOnUnset: true }
            ]} />
            <Row>
                <Col sm={6}>
                    <ManageUserScopes user={user} />
                </Col>
                <Col sm={6}>
                </Col>
            </Row>
        </div>
    )
}