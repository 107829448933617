import {createAsyncThunk, createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {UnifiedUser, unifiedUserKey} from "../Model/UnifiedUser";
import {UsersQuery} from "../Model/UsersQuery";
import {UsersApi} from "../Api/UsersApi";
import {apiProvider} from "./apiProvider";
import {shallowEqual} from "react-redux";

export interface UsersState {
    query: UsersQuery | null,
    users: UnifiedUser[] | null
    selected: UnifiedUser | null,
    currentQuery: UsersQuery | null,
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
    refreshed: UnifiedUser | null
}
export const initialState: UsersState = {
    query: null,
    users: null,
    currentQuery: null,
    selected: null,
    loadMessage: null,
    status: 'idle',
    refreshed: null
}

export const loadUsersAsync = createAsyncThunk(
    'users/loadUsersAsync',
    apiProvider(UsersApi, (request: UsersQuery, api: UsersApi, state) => {
        return api.queryUsers(request)
    })
)

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        selectUser: (state, action: PayloadAction<UnifiedUser | null>) => {
            state.selected = action.payload
            state.refreshed = null
        },
        changeUserQuery: (state, action: PayloadAction<UsersQuery | null>) => {
            state.query = action.payload
            state.users = null
            state.refreshed = null
        },
        refreshUser: (state) => {
            if (state.query !== null) {
                state.query = {...state.query}
                state.users = null
                state.refreshed = state.selected
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadUsersAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadUsersAsync.fulfilled, (state, action) => {
            const users = action.payload
            state.status = 'idle'
            state.loadMessage = null
            state.users = users
            const s = current(state)
            if (s.refreshed !== null && !shallowEqual(s.currentQuery, s.query)) {
                state.selected = null
            } else if (s.refreshed !== null) {
                state.selected = users.find(u => unifiedUserKey(u) === unifiedUserKey(s.refreshed as UnifiedUser)) ?? null
            }
            state.refreshed = null
            state.currentQuery = state.query
        }).addCase(loadUsersAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.users = null
            state.loadMessage = action.error.message ?? 'Error occurred loading users'
            state.selected = null
            state.refreshed = null
        })
    }
})

export default usersSlice.reducer;
export const { selectUser, changeUserQuery, refreshUser } = usersSlice.actions;
export const selectUsersState = (state: RootState) => state.users;
