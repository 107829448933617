import {useAppDispatch, useAppSelector} from "../../../../Core/Store/hooks";
import {loadManageUsersAsync, selectManageUser, selectManageUsersState} from "../../../../Core/Store/manageUsersSlice";
import {useRequest} from "../../../../Core/useRequest";
import {Card, Col, ListGroup, ListGroupItem, Row, Spinner} from "react-bootstrap";
import styles from "../../UsersDashboard/UsersDashboard.module.css";
import {ErrorView} from "../../../../Views/ErrorView";
import {UserDto} from "../../../../Core/Schema/models/UserDto";
import {ManageUserRow} from "../../../../Views/ManageUserRow/ManageUserRow";
import {ManageUserDetails} from "../../../../Views/ManageUserDetails/ManageUserDetails";

const SelectedManageUser = () => {
    const manageUsersState = useAppSelector(selectManageUsersState)
    const user = manageUsersState.selected
    if (user === null) {
        return null
    }
    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    {user.name}
                </Card.Title>
                <Card.Subtitle>
                    {user.disabled ? <span className={'manage-disabled-user'}>Disabled {user.disable_date?.toISOString()}</span> : <span className={'manage-enabled-user'}>Active user</span>}
                </Card.Subtitle>
            </Card.Header>
            <Card.Body>
                <ManageUserDetails user={user} />
            </Card.Body>
        </Card>
    )
}

export const ManageUsers = () => {
    const dispatch = useAppDispatch()
    const manageUsersState = useAppSelector(selectManageUsersState)
    useRequest(() => {
        dispatch(loadManageUsersAsync())
    }, manageUsersState.status, [])
    const onUserSelect = (eventKey: string | null) => {
        console.log('onSelect', eventKey)
        if (eventKey === null) {
            return
        }
        const user = manageUsersState.users?.find(e => eventKey === e.user_id.toString())
        if (user === undefined) {
            console.log('no user with key', eventKey)
            return
        }
        dispatch(selectManageUser(user))
    }
    return (
        <Row>
            <Col sm={3}>
                <Card>
                    <Card.Header>
                        <Card.Title className={styles.usersTitle}>
                            <div className={styles.titleWithProgress}>
                                <span>Users</span>
                                {manageUsersState.status === 'loading' && <span>
                                <Spinner size='sm' />
                            </span>}
                            </div>
                        </Card.Title>
                    </Card.Header>
                    <ErrorView {...manageUsersState} />
                    <ListGroup activeKey={manageUsersState.selected ? manageUsersState.selected.user_id : undefined} onSelect={onUserSelect}>
                        {manageUsersState.users?.map(
                            (user: UserDto) => {
                                const key = user.user_id
                                return (
                                    <ListGroupItem style={{ borderRadius: 0 }} action key={key} eventKey={key}>
                                        <ManageUserRow user={user} />
                                    </ListGroupItem>
                                )
                            }
                        )}
                    </ListGroup>
                    {manageUsersState.users && <Card.Footer>
                        <div>{manageUsersState.users.length ?? 0} user(s)</div>
                    </Card.Footer>}
                </Card>
            </Col>
            <Col>
                <SelectedManageUser />
            </Col>
        </Row>
    )
}