export interface UsersQuery {
    token?: string
    user_id?: number
    model?: string
}

export function usersQuery(model: string | undefined | null, query: string | null | undefined): UsersQuery {
    if (query === null || query === undefined || query.trim().length === 0) {
        throw new Error("Query is not specified")
    }
    query = query.trim()
    if (query.match(/\d+/) && query.length < 24) {
        const number = Number(query)
        if (!Number.isNaN(number) &&
            Number.isFinite(number) ||
            Number.isInteger(number)) {
            return { user_id: number, model: model ?? undefined, token: undefined }
        }
    }
    if (query.match(/^(?:ST_|DEV_)?[A-Z0-9a-z]{26,33}$/gm)) {
        return { token: query, model: model ?? undefined, user_id: undefined }
    }
    throw new Error("Query is not valid ID or Token")
}